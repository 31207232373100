import React, { useState, useEffect, useContext } from "react";
import { Button, ToggleSwitch, Input } from "@abb/abb-common-ux-react";
import { RootContext } from "../../../../contexts/root-context";
import axios from 'axios';
import "./objective-fun.css";
import { EMCORE_BASE_API_URL } from "../../../../strings";
import microGridAPICall from "../../api";

const ObjectiveFunction = () => {
  const { setisnotify } = useContext(RootContext);

  // Default values from the API
  const [defaultData, setDefaultData] = useState(null);

  // State management for all inputs
  const [peakShavingEnabled, setPeakShavingEnabled] = useState(false);
  const [peakShavingWeight, setPeakShavingWeight] = useState("0.0");
  const [energyArbitrageEnabled, setEnergyArbitrageEnabled] = useState(true);
  const [energyArbitrageWeight, setEnergyArbitrageWeight] = useState("1.0");
  const [loadSheddingEnabled, setLoadSheddingEnabled] = useState(false);
  const [loadSheddingWeight, setLoadSheddingWeight] = useState("0");
  const [profileSmoothingEnabled, setProfileSmoothingEnabled] = useState(false);
  const [profileSmoothingWeight, setProfileSmoothingWeight] = useState("0");
  
  const [previousHighestPeakKW, setPreviousHighestPeakKW] = useState("2000");
  const [priceHighestPeakCurrency, setPriceHighestPeakCurrency] = useState("0.08");
  const [endCurrentBillingPeriod, setEndCurrentBillingPeriod] = useState("2016-01-01 23:59:59");

  const [includeEnergyTracking, setIncludeEnergyTracking] = useState(false);
  const [isSaveEnabled, setIsSaveEnabled] = useState(true);

  // Fetch default data from API on mount
  useEffect(() => {
    const fetchDefaultData = async () => {
      try {
        const response = await axios.get("https://emcore-data-bucket-test.s3.amazonaws.com/input/parameters.json");
        setDefaultData(response.data);
        console.log("response.data obj",response.data)
        // Update form fields with default values from the API
        const objectives = response.data.objectives;
        setPeakShavingEnabled(objectives.peak_shaving.realize);
        setPeakShavingWeight(objectives.peak_shaving.weighting);
        setEnergyArbitrageEnabled(objectives.energy_arbitrage.realize);
        setEnergyArbitrageWeight(objectives.energy_arbitrage.weighting);
        setLoadSheddingEnabled(objectives.load_shedding.realize);
        setLoadSheddingWeight(objectives.load_shedding.weighting);
        setProfileSmoothingEnabled(objectives.profile_smoothing.realize);
        setProfileSmoothingWeight(objectives.profile_smoothing.weighting);

        const context = response.data.context;
        setPreviousHighestPeakKW(context.previous_highest_peak_kW);
        setPriceHighestPeakCurrency(context.price_highest_peak_currency_per_kW_billing_period);
        setEndCurrentBillingPeriod(context.end_of_current_billing_period);

        setIncludeEnergyTracking(response.data.configuration.include_energy_tracking);
      } catch (error) {
        console.error("Error fetching default data:", error);
      }
    };

    fetchDefaultData();
  }, []);

  // // Validate input fields and toggle Save button state
  // useEffect(() => {
  //   if (
  //     peakShavingWeight && energyArbitrageWeight && loadSheddingWeight &&
  //     profileSmoothingWeight && previousHighestPeakKW && priceHighestPeakCurrency && endCurrentBillingPeriod
  //   ) {
  //     setIsSaveEnabled(true);
  //   } else {
  //     setIsSaveEnabled(false);
  //   }
  // }, [
  //   peakShavingWeight, energyArbitrageWeight, loadSheddingWeight, profileSmoothingWeight,
  //   previousHighestPeakKW, priceHighestPeakCurrency, endCurrentBillingPeriod
  // ]);

  const handleSaveUserInfo = async () => {
    setisnotify("");
    const payload = {
      objectives: {
        peak_shaving: { realize: peakShavingEnabled, weighting: peakShavingWeight },
        energy_arbitrage: { realize: energyArbitrageEnabled, weighting: energyArbitrageWeight },
        load_shedding: { realize: loadSheddingEnabled, weighting: loadSheddingWeight },
        profile_smoothing: { realize: profileSmoothingEnabled, weighting: profileSmoothingWeight }
      },
      context: {
        previous_highest_peak_kW: previousHighestPeakKW,
        price_highest_peak_currency_per_kW_billing_period: priceHighestPeakCurrency,
        end_of_current_billing_period: endCurrentBillingPeriod
      },
      configuration: { include_energy_tracking: includeEnergyTracking }
    };

    try {
      const response = await microGridAPICall('post', `${EMCORE_BASE_API_URL}generate-charts`, payload, {});
      console.log('Data saved successfully:', response);
      const dashboardS3Url = response.s3url;
      localStorage.setItem("dashboard-charts-s3-url", dashboardS3Url);
      setisnotify("objfuntions");
    } catch (error) {
      console.error('There was an error saving the data!', error);
    }
  };
  return (
    <div className="edit-user-container">
      <table id="edit-user-table">
        {/* Peak Shaving */}
        <tr><td><b>Peak Shaving</b></td></tr>
        <tr>
          <td><ToggleSwitch  label={peakShavingEnabled? "Enable" : "Disable"}  value={peakShavingEnabled} onChange={setPeakShavingEnabled} /></td>
        </tr>
        <tr>
          <td>
            <Input
            type="normal"
              label="Weightage"
              value={peakShavingWeight}
              placeholder="Enter weighting"
              onValueChange={(val) =>
               
               setPeakShavingWeight(val)
              }
             // onValueChange={e => setPeakShavingWeight(e.target.value)}
            />
          </td>
        </tr>

        {/* Energy Arbitrage */}
        <tr><td><b>Energy Arbitrage</b></td></tr>
        <tr>
          <td><ToggleSwitch  label={energyArbitrageEnabled? "Enable" : "Disable"} value={energyArbitrageEnabled} onChange={setEnergyArbitrageEnabled} /></td>
        </tr>
        <tr>
          <td>
            <Input
              label="Weightage"
              value={energyArbitrageWeight}
              placeholder="Enter weighting"
              onValueChange={(val) =>
                setEnergyArbitrageWeight(val)
               }
            />
          </td>
        </tr>

        {/* Load Shedding */}
        <tr><td><b>Load Shedding</b></td></tr>
        <tr>
          <td><ToggleSwitch label={loadSheddingEnabled? "Enable" : "Disable"} value={loadSheddingEnabled} onChange={setLoadSheddingEnabled} /></td>
        </tr>
        <tr>
          <td>
            <Input
              label="Weightage"
              value={loadSheddingWeight}
              placeholder="Enter weighting"
              onValueChange={(val) =>
                setLoadSheddingWeight(val)
               }
            />
          </td>
        </tr>

        {/* Profile Smoothing */}
        <tr><td><b>Profile Smoothing</b></td></tr>
        <tr>
          <td><ToggleSwitch label={profileSmoothingEnabled? "Enable" : "Disable"} value={profileSmoothingEnabled} onChange={setProfileSmoothingEnabled} /></td>
        </tr>
        <tr>
          <td>
            <Input
              label="Weightage"
              value={profileSmoothingWeight}
              placeholder="Enter weighting"
              onValueChange={(val) =>
                setProfileSmoothingWeight(val)
               }
            />
          </td>
        </tr>

        {/* Context Section */}
        <tr><td><b>Context</b></td></tr>
        <tr>
          <td>
            <Input
              label="Previous Highest Peak kW"
              value={previousHighestPeakKW}
              placeholder="Enter kW"
              onValueChange={(val) =>
                setPreviousHighestPeakKW(val)
               }
            />
          </td>
        </tr>
        <tr>
          <td>
            <Input
              label="Highest peak price per MW (€)"
              value={priceHighestPeakCurrency}
              placeholder="Enter price"
              onValueChange={(val) =>
                setPriceHighestPeakCurrency(val)
               }
            />
          </td>
        </tr>
        <tr>
          <td>
            <Input
              label="End of Current Billing Period"
              value={endCurrentBillingPeriod}
              placeholder="Enter billing period"
              onValueChange={(val) =>
                setEndCurrentBillingPeriod(val)
               }
            />
          </td>
        </tr>

        {/* Configuration Section */}
        <tr>
          <td>
            <span>Include Energy Tracking</span>
            <ToggleSwitch
              label={includeEnergyTracking ? "Enable" : "Disable"}
              value={includeEnergyTracking}
              onChange={setIncludeEnergyTracking}
            />
          </td>
        </tr>

        {/* Save Button */}
        <tr>
          <td>
            <Button
              text="Run"
              shape="pill"
              sizeClass="small"
              type="primary-black"
              onClick={handleSaveUserInfo}
              disabled={!isSaveEnabled} // Save button is disabled unless all inputs are filled
            />
            <Button text="Cancel" shape="pill" sizeClass="small" />
          </td>
        </tr>
      </table>
    </div>
  );
};

export default ObjectiveFunction;

