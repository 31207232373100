import React, { useContext, useEffect } from "react";
import { Icon } from "@abb/abb-common-ux-react";
import "./metrics.css";
import { RootContext } from "../../../contexts/root-context";
function MicroOptMetrics(props) {
    const { startTS, selectedTimeTitle } = useContext(RootContext);
    useEffect(() => {
    }, [startTS])
    //Metrics Names
    const { legends } = props;
    return (
        <div style={{ backgroundColor: 'white' }}>
            {legends?.map((legend, index) => {
                switch (legend.metricName) {
                    case "# Load Demand":
                        return <div id="dashboard-metrics" key={index}>
                            <span className="metric-name">{legend.metricName}</span>
                            <div id="metrics-details">
                                <span className={"metric-score"}>{legend.metricScore}
                                    &nbsp;&nbsp;
                                    <span style={{ lineHeight: "24px" }}
                                        className={"metric-unit"}>
                                        {legend.metricStat}
                                    </span>
                                </span>
                            </div>
                        </div>
                    case "# Green Energy Gen.":
                        return <div id="dashboard-metrics" key={index}>
                            <span className="metric-name">{legend.metricName}</span>
                            <div id="metrics-details">
                                <span className={"metric-score"}>{legend.metricScore}
                                    &nbsp;&nbsp;
                                    <span style={{ lineHeight: "24px" }}
                                        className={"metric-unit"}>
                                        {legend.metricStat}
                                    </span>
                                </span>
                            </div>
                        </div>
                    case "# Non-Green Energy Gen.":
                        return <div id="dashboard-metrics" key={index}>
                            <span className="metric-name">{legend.metricName}</span>
                            <div id="metrics-details">
                                <span className={"metric-score"}>{legend.metricScore}
                                    &nbsp;&nbsp;
                                    <span style={{ lineHeight: "24px" }}
                                        className={"metric-unit"}>
                                        {legend.metricStat}
                                    </span>
                                </span>
                            </div>
                        </div>
                    case "# Generation":
                        return <div id="dashboard-metrics" key={index}>
                            <span className="metric-name">{legend.metricName}</span>
                            <div id="metrics-details">
                                <span className={"metric-score"}>{legend.metricScore}
                                    &nbsp;&nbsp;
                                    <span style={{ lineHeight: "24px" }}
                                        className={"metric-unit"}>
                                        {legend.metricStat}
                                    </span>
                                </span>
                            </div>
                        </div>
                    case "# Energy Cost":
                        return <div id="dashboard-metrics" key={index}>
                            <span className="metric-name">{legend.metricName}</span>
                            <div id="metrics-details">
                                <span className={"metric-score"}>{legend.metricScore}
                                    &nbsp;&nbsp;
                                    <span style={{ lineHeight: "24px" }}
                                        className={"metric-unit"}>
                                        {legend.metricStat}
                                    </span>
                                </span>
                            </div>
                        </div>
                    case "# Highest Peak":
                        return <div id="dashboard-metrics" key={index}>
                            <span className="metric-name">{legend.metricName}</span>
                            <div id="metrics-details">
                                <span className={"metric-score"}>{legend.metricScore}
                                    &nbsp;&nbsp;
                                    <span style={{ lineHeight: "24px" }}
                                        className={"metric-unit"}>
                                        {legend.metricStat}
                                    </span>
                                </span>
                            </div>
                        </div>
                    case "# Grid Intake":
                        return <div id="dashboard-metrics" key={index}>
                            <span className="metric-name">{legend.metricName}</span>
                            <div id="metrics-details">
                                <span className={"metric-score"}>{legend.metricScore}
                                    &nbsp;&nbsp;
                                    <span style={{ lineHeight: "24px" }}
                                        className={"metric-unit"}>
                                        {legend.metricStat}
                                    </span>
                                </span>
                            </div>
                        </div>
                    case "# Peak Costs":
                        return <div id="dashboard-metrics" key={index}>
                            <span className="metric-name">{legend.metricName}</span>
                            <div id="metrics-details">
                                <span className={"metric-score"}>{legend.metricScore}
                                    &nbsp;&nbsp;
                                    <span style={{ lineHeight: "24px" }}
                                        className={"metric-unit"}>
                                        {legend.metricStat}
                                    </span>
                                </span>
                            </div>
                        </div>
                }
            })
            }
        </div >
    );

}
export default MicroOptMetrics;


