import React, { useContext } from "react";
import { Gauge } from "@abb/abb-common-ux-react";
import "./co2-savings.css";
import { RootContext } from "../../../contexts/root-context";

function CO2Saving() {
  const { siteKpis } = useContext(RootContext);

  // bar 1
  const co2SavingsTargetTitle =
    siteKpis?.siteKPIMetrics?.co2Savings?.target || "";
  const valueForCo2Savings =
    siteKpis?.siteKPIMetrics?.co2Savings?.value || 1234;
  const maxCo2Savings = siteKpis?.siteKPIMetrics?.co2Savings?.target || 4200;
  const [progressBar1ValueCo2Saving, setProgressBar1ValueCo2Saving] =
    React.useState(valueForCo2Savings);
  const [progressBar1RangeCo2Saving, setProgressBar1RangeCo2Saving] =
    React.useState([0, maxCo2Savings]);

  // bar 2
  const co2SavingsLastYearTitle =
    siteKpis?.siteKPIMetrics?.co2Savings?.lastYear || "";
  const valueForCo2SavingsLastYear =
    siteKpis?.siteKPIMetrics?.co2Savings?.lastYear || 5900;
  const maxCo2SavingsLastYear =
    siteKpis?.siteKPIMetrics?.co2Savings?.target || 5900;
  const [progressBar2ValueCo2Saving, setProgressBar2ValueCo2Saving] =
    React.useState(valueForCo2SavingsLastYear);
  const [progressBar2RangeCo2Saving, setProgressBar2RangeCo2Saving] =
    React.useState([0, maxCo2SavingsLastYear]);

  // measure
  const measureValueCo2Saving =
    siteKpis?.siteKPIMetrics?.co2Savings?.value || 1234;
  const measureKpiCo2Saving =
    siteKpis?.siteKPIMetrics?.co2Savings?.kpi || "+10% YoY";

  const [decimals, setDecimals] = React.useState(0);
  const [warnings, setWarnings] = React.useState([25, 75]);
  const [alarms, setAlarms] = React.useState([10, 90]);
  const [limitMarkerStyle, setLimitMarkerStyle] = React.useState("none");

  return (
    <div className="co2-saving-container">
      <div className="co2-saving-container-top-div">
        <Gauge
          style={{ width: "50%" }}
          type="bar"
          label="Target "
          min="0"
          max={progressBar1RangeCo2Saving[1]}
          minLabel={0 + "%"}
          maxLabel={100 + "%"}
          value={progressBar1ValueCo2Saving}
          formatValue={(val) => `${val})`}
        />
        <div style={{ width: "50%", marginTop: "4%", marginLeft: "1%" }}>
          <span>YTD</span>
          <div className="divider"></div>
        </div>
      </div>
      <div className="number">300</div>
      <div className="co2-saving-container-bottom-div">
        <Gauge
          style={{ width: "50%" }}
          type="bar"
          label="Last Year  "
          min="0"
          max={progressBar2RangeCo2Saving[1]}
          minLabel={0 + "%"}
          maxLabel={100 + "%"}
          value={progressBar2ValueCo2Saving}
          formatValue={(val) => `${val})`}
        />
        <div style={{ width: "50%", marginTop: "35px", marginLeft: "1%" }}>
          <div className="divider"></div>
          <span> +4% YoY</span>
        </div>
      </div>
    </div>
  );
}

export default CO2Saving;
