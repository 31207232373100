import React, { useEffect, useRef } from 'react';
import { Dropdown, DropdownOption } from '@abb/abb-common-ux-react';

const PeriodList = () => {

    const periodOptions = ["Today", "Yesterday", "Current week", "Current month", "Current semester", "Current year"];


    return (<Dropdown
        label="Period"
        placeholder="Today"
        style={{ paddingLeft: "3px" }}
    >
        {periodOptions.map((o, i) => (
            <DropdownOption key={o} label={o} value={o} />
        ))}

    </Dropdown>
    )
};

export default PeriodList;
