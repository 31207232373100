import React, { useState, useEffect, useContext } from "react";
import "./voltage-trend.css";
import axios from "axios";
import { RootContext } from "../../contexts/root-context";
import { BASE_URL, ERROR_LIST } from "../../strings";

import {
  Label,
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ReferenceArea,
  Legend,
} from "recharts";

const VoltageTrend = () => {
  const { startTS, endTS } = useContext(RootContext); //setSpinner is used to show the spinner when the page is loading

  const [data, setData] = useState([]);
  const getAxisYDomain = (from, to, ref, offset) => {
    if (ref !== undefined) {
      try {
        const refData = data?.slice(from - 1, to);

        let [bottom, top] = [refData[0][ref], refData[0][ref]];
        refData.forEach((d) => {
          if (d[ref] > top) top = d[ref];
          if (d[ref] < bottom) bottom = d[ref];
        });
      } catch (error) {
        console.log("zoom error", error);
      }
      return [(bottom | 0) - offset, (top | 0) + offset];
    }
  };

  const [chartState, setChartState] = useState({
    dataSet: data,
    left: "dataMin",
    right: "dataMax",
    refAreaLeft: "",
    refAreaRight: "",
    top: "dataMax+1",
    bottom: "dataMin-1",
    top2: "dataMax+20",
    bottom2: "dataMin-20",
    top3: "dataMax+40",
    bottom3: "dataMin-40",
    animation: true,
  });
  const zoomOut = () => {
    setChartState({
      dataSet: data.slice(),
      refAreaLeft: "",
      refAreaRight: "",
      left: "dataMin",
      right: "dataMax",
      top: "dataMax+1",
      top2: "dataMax+1",
      top3: "dataMax+1",
      bottom: "dataMin",
      bottom2: "dataMin",
      bottom3: "dataMin",
    });
  };
  const zoom = () => {
    let { refAreaLeft, refAreaRight, data } = chartState;

    if (refAreaLeft === refAreaRight || refAreaRight === "") {
      setChartState({
        refAreaLeft: "",
        refAreaRight: "",
      });
      return;
    }

    // xAxis domain
    if (refAreaLeft > refAreaRight)
      [refAreaLeft, refAreaRight] = [refAreaRight, refAreaLeft];

    // yAxis domain
    const [bottom, top] = getAxisYDomain(refAreaLeft, refAreaRight, "U1A", 20);
    const [bottom2, top2] = getAxisYDomain(
      refAreaLeft,
      refAreaRight,
      "U2B",
      20
    );
    const [bottom3, top3] = getAxisYDomain(
      refAreaLeft,
      refAreaRight,
      "U3C",
      20
    );

    setChartState({
      refAreaLeft: "",
      refAreaRight: "",
      dataSet: data?.slice(),
      left: refAreaLeft,
      right: refAreaRight,
      bottom,
      top,
      bottom2,
      top2,
      top3,
      bottom3,
    });
  };
  useEffect(() => {
    callAPI();
  }, [startTS]);

  const getMaxYValue = (arr) => {
    console.log("arr max", arr);
    let maxIL1 = Math.max.apply(
      Math,
      arr.map(function (o) {
        return o.IL1;
      })
    );
    let maxIL2 = Math.max.apply(
      Math,
      arr.map(function (o) {
        return o.IL2;
      })
    );
    let maxIL3 = Math.max.apply(
      Math,
      arr.map(function (o) {
        return o.IL3;
      })
    );
    let compareMax = [maxIL1, maxIL2, maxIL3];
    return Math.max(...compareMax);
  };
  const callAPI = () => {
    // let { data } = chartState;
    // let startLocalTS = chartState.startTime;
    axios
      .get(
        BASE_URL +
          `SMDemo-VoltageTrendServiceLambda?startDateTime=${startTS}&endDateTime=${endTS}`
      )
      .then((response) => {
        let cTrendResp = response.data.dataPoints;
        console.log("Cur Trend", cTrendResp);
        let updatedcTrendResp = cTrendResp.map((v, i) => ({
          time: `${v.startDateTime.time.hour}`,
          cust_time: `${v.startDateTimeStr.split("T")[1].replace(":", ".")}`,
          U1A: parseInt(v.voltageAB),
          U2B: parseInt(v.voltageBC),
          U3C: parseInt(v.voltageCA),
        }));
        setData(updatedcTrendResp);
        setChartState(chartState);
      });
  };
  const {
    left,
    dataSet,
    right,
    refAreaLeft,
    refAreaRight,
    top,
    bottom,
    top2,
    bottom2,
    top3,
    bottom3,
  } = chartState;

  return (
    <>
      {data.length > 0 ? (
        <div className="highlight-bar-charts">
          <button
            type="button"
            onClick={zoomOut}
            className="btn update zoomout_trend"
          >
            {/*   zoomout_trend */}
            Reset Zoom
          </button>
          <LineChart
            className="voltage-style"
            width={400}
            height={210}
            data={data}
            margin={{ top: 15, right: 20, left: 40, bottom: 5 }}
            onMouseDown={(e) =>
              setChartState({ ...chartState, refAreaLeft: e?.activeLabel })
            }
            onMouseMove={(e) =>
              chartState?.refAreaLeft &&
              setChartState({ ...chartState, refAreaRight: e?.activeLabel })
            }
            onMouseUp={zoom}
          >
            <CartesianGrid strokeDasharray="3" opacity={0.5} stroke="false" />

            <XAxis
              allowDataOverflow={true}
              dataKey="cust_time"
              domain={[left, right]}
              type="category"
            >
              <Label value="Time" offset={0} position="insideBottom" />
            </XAxis>
            <YAxis
              allowDataOverflow={true}
              domain={[bottom, top]}
              type="number"
              yAxisId="1"
              label={{
                value: "Voltage(kV)",
                angle: -90,
                position: "insideLeft",
              }}
            />

            <Tooltip />
            <Legend verticalAlign="top" iconType="rect" />

            <Line yAxisId="1" dataKey="U1A" stroke="#E12E2E" dot={false} />
            <Line yAxisId="1" dataKey="U2B" stroke="#1B7AC2" dot={false} />
            <Line yAxisId="1" dataKey="U3C" stroke="#0A0A0A" dot={false} />

            {refAreaLeft && refAreaRight ? (
              <ReferenceArea
                yAxisId="1"
                x1={refAreaLeft}
                x2={refAreaRight}
                strokeOpacity={0.3}
              />
            ) : null}
          </LineChart>
        </div>
      ) : (
        <p className="center-text-error">{ERROR_LIST.NO_DATA}</p>
      )}
    </>
  );
};
export default VoltageTrend;
