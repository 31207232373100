import React from "react";
import "./sites.css";

function Sites() {
  return (
    <div className="map-area" style={{ textAlign: "left" }}>
      <iframe
        src="https://www.google.com/maps/d/embed?mid=12t3OCYsRf1WRNpIKqaaXSluJjVx8ADbw&ehbc=2E312F"
        width="100%"
        height="100%"
        style={{ border: 0 }}
        allowFullScreen="true"
        loading="lazy"
        title="Google Map"
      ></iframe>
    </div>
  );
}
export default Sites;
