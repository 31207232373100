import React, { useContext } from "react";
import { DropdownOption, Dropdown } from "@abb/abb-common-ux-react";
import getTimestamps from "../../util";
import { RootContext } from "../../contexts/root-context";
import "./kpi-chart.css";
import PeriodList from "../Period-Dropdown-list/period-dropdown-list";
const KPIChart = () => {
  const { setstartTS, endTS, setendTS } = useContext(RootContext);
  const [monochrome, setMonochrome] = React.useState(false);
  const [selectedSingle, setSelectedSingle] = React.useState("Today");
  const getFilterTime = (filterVal) => {
    setSelectedSingle(String(filterVal[0].label));
    setstartTS(getTimestamps(filterVal[0].value).startDate);
    setendTS(getTimestamps(filterVal[0].value).endDate);
  };

  return (
    <>
      <div style={{ width: "100%" }}>
        <div className="indicator-container-top">
          <Dropdown
            label="ENPI"
            placeholder="Energy/Area"
            monochrome={monochrome}
          >
            <DropdownOption
              key="Site attendance"
              label="Energy/Area"
              value="Site attendance"
            />
          </Dropdown>
          <Dropdown
            style={{ paddingLeft: "3px", width: "130px" }}
            label="Conversion"
            placeholder="Energy"
            monochrome={monochrome}
          >
            <DropdownOption key="Energy" label="Energy" value="Energy" />
          </Dropdown>
          <PeriodList />
        </div>

        <div style={{ height: "190px" }}>
          <span>Today</span>
          <h1>18,85</h1>
          <span>
            kWh/m<sup>2</sup>
          </span>
        </div>
        <div className="indicator-container">
          <div className="inside-metrics">
            <span className="im1">ENPI</span>
            <span className="im2">UNIT OF MEASUREMENT</span>
            <span className="im3">NUMERATOR</span>
            <span className="im4"> DENOMINATOR</span>
          </div>
        </div>
        <hr className="ind-line" />
        <div className="indicator-container">
          <div className="inside-metrics">
            <span className="im1">Efficiency</span>
            <span className="im2">
              kWh/m<sup>2</sup>
            </span>
            <span className="im3">Active Energy</span>
            <span className="im4"> Location Surface</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default KPIChart;
