export const awsConfig = {
    // aws_cognito_region: process.env.REACT_APP_AWS_COGNITO_REGION, // (required) - Region where Amazon Cognito project was created
    // aws_user_pools_id: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,// (optional) -  Amazon Cognito User Pool ID
    // aws_user_pools_web_client_id: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID, // (optional) - Amazon Cognito App Client ID (App client secret needs to be disabled)
    //Subbu added of AD integration
    "aws_project_region": process.env.REACT_APP_AWS_COGNITO_REGION, // REGION
    "aws_cognito_region": process.env.REACT_APP_AWS_COGNITO_REGION, // REGION
    "aws_user_pools_id": process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID, // ENTER YOUR USER POOL ID
    "aws_user_pools_web_client_id": process.env.REACT_APP_AWS_COGNITO_CLIENT_ID, // ENTER YOUR CLIENT ID
    "oauth": {
        "domain": `${process.env.REACT_APP_AWS_COGNITO_DOMAIN}.auth.${process.env.REACT_APP_AWS_COGNITO_REGION}.amazoncognito.com`,
        "scope": [
            "email",
            "openid",
            "profile"
        ],
        "redirectSignIn": process.env.REACT_APP_AWS_COGNITO_REDIRECT_SIGNIN,
        "redirectSignOut": process.env.REACT_APP_AWS_COGNITO_REDIRECT_SIGNOUT,
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "awsConfigUserpool": {
        region: process.env.REACT_APP_AWS_COGNITO_REGION,
        credentials: {
            accessKeyId: process.env.REACT_APP_AWS_COGNITO_ACCESS_KEY,
            secretAccessKey: process.env.REACT_APP_AWS_COGNITO_SECRET_ACCESS_KEY
        }
    }
};