import React, { useEffect, useRef, useState } from 'react';
import * as echarts from 'echarts';
import "./power-demand.css"
import fetchData from '../../api';
import { EM_BASE_URL, PLANT_TYPE, IS_OPTIONS, TOKEN, EM_SUBSCRIPTION_KEY } from '../../strings';

const PowerDemand = () => {
    const chartRef = useRef(null);
    const myChart = useRef(null);
    const [dataA, setDataA] = useState(null);

    // useEffect(() => {
    //     const fetchPowerDemand = async () => {
    //         console.log("TOKEN", TOKEN)
    //         const data = await fetchData({
    //             method: 'GET',
    //             url: EM_BASE_URL,
    //             headers: { Authorization: `Bearer ${TOKEN}` }, // Custom headers if needed
    //             params: { isOptions: IS_OPTIONS, plantType: PLANT_TYPE }, // Request parameters if needed
    //         });
    //         console.log("PD", data)
    //         setDataA(data);
    //     };
    //     fetchPowerDemand();
    // }, []);

    useEffect(() => {
        myChart.current = echarts.init(chartRef.current);

        const option = {
            series: [
                {
                    type: 'gauge',
                    center: ['50%', '60%'],
                    startAngle: 200,
                    endAngle: -20,
                    min: 0,
                    max: 100,
                    progress: {
                        show: true,
                        width: 10,
                        itemStyle: {
                            color: '#51A34B', // Color for the data range
                        },
                        backgroundStyle: {
                            color: '#DBDBDB' // Color for the data track (background)
                        }
                    },
                    detail: {
                        formatter: '{value}%\nPower Demand',

                        offsetCenter: [0, 0],// Adjust offsetCenter to center the value text

                        textStyle: { // Add textStyle to modify font size
                            fontSize: 18, // Adjust the font size here

                        }
                        // textStyle: {
                        //     color: '#51A34B', // Color for the value text
                        // }
                    },
                    axisLine: {
                        lineStyle: {
                            width: 10, // Set axis line width to 0 to hide it
                        }
                    },
                    axisLabel: {
                        show: false, // Hide axis labels
                    },
                    axisTick: {
                        show: false, // Hide axis ticks
                    },
                    splitLine: {
                        show: false, // Hide split lines
                    },
                    pointer: {
                        show: false, // Hide pointer
                    },
                    data: [
                        { value: 13 }
                    ],
                }
            ]
        };

        myChart.current.setOption(option);

        // Uncomment this code if you want to update the gauge value periodically
        // const updateDataInterval = setInterval(() => {
        //     const random = +(Math.random() * 60).toFixed(2);
        //     myChart.current.setOption({
        //         series: [
        //             {
        //                 data: [{ value: random }]
        //             }
        //         ]
        //     });
        // }, 2000);

        // return () => clearInterval(updateDataInterval);
    }, []);

    return (
        <div className='power-container'>
            <div className='chart-holder'>
                <div className='chart-container' ref={chartRef}></div>
            </div>
            <div className='power-info'>
                <div className='power-inner'>
                    <div className='power-metrics'>
                        <span>Active power</span>
                        <span style={{ "fontSize": "16px", "fontWeight": "bold" }}>50,0 kW</span>
                    </div>
                    <div className='power-metrics'>
                        <span>Contractual power</span>
                        <span style={{ "fontSize": "16px", "fontWeight": "bold" }}>400,0 kW</span>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default PowerDemand;
