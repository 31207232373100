import React, { useEffect, useState, useContext } from "react";
import "./sldDevices.css"
import closedGateImg from "../../../assets/net-zero-images/closed-gate.png";
import openedGateImg from "../../../assets/net-zero-images/opened-gate.png";
import isolatedGateImg from "../../../assets/net-zero-images/isolated-gate.png";
import trippedGateImg from "../../../assets/net-zero-images/tripped-gate.png";
import { RootContext } from "../../../contexts/root-context";

const SldDevice = ({ device }) => {

    const getClassNames = () => {
        let classNames = "sld-box";
        if (device.isOpen) classNames += " opened";
        if (device.isClosed) classNames += " closed";
        if (device.isTripped) classNames += " tripped";
        if (device.isIsolated) classNames += " isIsolated";
        return classNames;
    };
    const { digitalTwinsData } = useContext(RootContext);
    // Find the device status from digitalTwinsData based on device.device
    const currentDevice = digitalTwinsData?.find(item => item.device === device.id);

    const status = currentDevice ? currentDevice.status : "closed"; // Default to "closed" if status not found

    // Determine which image to use based on device status
    let statusImage;
    switch (status) {
        case "closed":
            statusImage = closedGateImg;
            break;
        case "opened":
            statusImage = openedGateImg;
            break;
        case "isolated":
            statusImage = isolatedGateImg;
            break;
        case "tripped":
            statusImage = trippedGateImg;
            break;
        default:
            statusImage = closedGateImg; // Default to closed gate image if status is unknown
            break;
    }

    return (
        <>
            {!["ITC", "BUSBAR_C", "BUSBAR_B"].includes(device.id) && (
                <div className={getClassNames()} style={{ top: device.y, left: device.x }}>
                    <div className="Sld-device-content">
                        <div>{device.name}</div>
                        <div>{device.value}</div>
                    </div>
                </div>
            )}

            {device.id && (
                <img
                    key={device.id}
                    src={statusImage}
                    alt={status === "closed" ? "Closed gate" : "Opened gate"}
                    style={{
                        position: 'absolute',
                        top: device.imgy,
                        left: device.imgx,
                        width: '48px',
                        transform: (device.id === "BUSBAR_C" || device.id === "BUSBAR_B") ? 'rotate(90deg)' : 'none'
                    }}
                />
            )}
        </>
    );
};

export default SldDevice;