import React, { useContext, useState } from "react";
import { Gauge, Spinner } from "@abb/abb-common-ux-react";
import axios from "axios";
import { RootContext } from "../../contexts/root-context";
import "./critical-alerts.css";
import { BASE_URL, ERROR_LIST } from "../../strings";
function CritocalAlerts() {
  const { startTS, endTS } = useContext(RootContext); //setSpinner is used to show the spinner when the page is loading
  const [criticalAlertsResponse, setcriticalAlertsResponse] = useState({});
  const [isSpin, setispin] = useState(true);
  const [error, setError] = useState(false);
  React.useEffect(() => {
    let getPowerMeasurementData = [
      {
        startDateTime: "03/14/2023 00:00:00",
        endDateTime: "03/14/2023 18:25:00",
      },
    ];

    const fetchData = async () => {
      try {
        const response = await axios.get(
          BASE_URL +
            `SMDemo-CriticalAlertsServiceLambda?startDateTime=${startTS}&endDateTime=${endTS}`
        );
        setcriticalAlertsResponse(response.data);
        setispin(false);
      } catch (error) {
        if (error.response) {
          const status = error.response.status;
          if (status >= 400 && status < 500) {
            setError(true);
          } else if (status >= 500 && status < 600) {
            setError(true);
          } else {
            setError(true);
          }
        } else {
          fetchData();
          setispin(true);
        }
      }
    };
    fetchData();
  }, [startTS]);

  return (
    <>
      {isSpin ? (
        <Spinner style={{ margin: "auto" }} color="dark-grey" size="small" />
      ) : (
        <>
          {error ? (
            <span style={{ margin: "auto" }} color="dark-grey" size="small">
              {ERROR_LIST.ERROR_FETCH_DATA}
            </span>
          ) : (
            <>
              <Gauge
                className="gauge-style"
                type="radial"
                min={criticalAlertsResponse?.thresholdExceeded?.minRange}
                max={
                  parseInt(
                    criticalAlertsResponse?.thresholdExceeded?.maxRange
                  ) == 0
                    ? 1
                    : parseInt(
                        criticalAlertsResponse?.thresholdExceeded?.maxRange
                      )
                }
                minLabel={criticalAlertsResponse?.thresholdExceeded?.minRange}
                maxLabel={parseInt(
                  criticalAlertsResponse?.thresholdExceeded?.maxRange
                )}
                highAlarm="10"
                // highAlarm={
                //   criticalAlertsResponse?.thresholdExceeded?.thresholds?.maxRange
                // }
                label={criticalAlertsResponse?.thresholdExceeded?.title}
                unit={criticalAlertsResponse?.thresholdExceeded?.unit}
                value={
                  parseInt(
                    criticalAlertsResponse?.thresholdExceeded?.currentValue
                  ) === undefined
                    ? "0"
                    : parseInt(
                        criticalAlertsResponse?.thresholdExceeded?.currentValue
                      )
                }
              />

              <Gauge
                className="gauge-style"
                type="radial"
                min={criticalAlertsResponse?.equipFailures?.minRange}
                max={
                  parseFloat(
                    criticalAlertsResponse?.equipFailures?.maxRange
                  ) === 0
                    ? 1
                    : parseInt(criticalAlertsResponse?.equipFailures?.maxRange)
                }
                minLabel={criticalAlertsResponse?.equipFailures?.minRange}
                maxLabel={parseInt(
                  criticalAlertsResponse?.equipFailures?.maxRange
                )}
                highAlarm={10}
                label={criticalAlertsResponse?.equipFailures?.title}
                unit={criticalAlertsResponse?.equipFailures?.unit}
                value={
                  parseInt(
                    criticalAlertsResponse?.equipFailures?.currentValue
                  ) === undefined
                    ? 0
                    : parseInt(
                        criticalAlertsResponse?.equipFailures?.currentValue
                      )
                }
              />
            </>
          )}
        </>
      )}
    </>
  );
}

export default CritocalAlerts;
