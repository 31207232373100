import React, { useState, useContext } from "react";
import axios from "axios";
import { RootContext } from "../../contexts/root-context";
import {
  DonutChart,
  ToggleButton,
  ToggleButtonGroup,
  Spinner,
  Link,
} from "@abb/abb-common-ux-react";
import "./event-log.css";
import { BASE_URL, BASE_URL_EVENT_LOGS, ERROR_LIST } from "../../strings";
import { getEventLogByFunctionLegends } from "../../util";
function EventLog() {
  const { startTS, endTS } = useContext(RootContext); //setSpinner is used to show the spinner when the page is loading

  const [isSpin, setispin] = useState(true);
  const [error, setError] = useState(false);
  React.useEffect(() => {
    fetchEventLogsByFunctionData();
  }, [startTS]);

  let fetchEventLogsByFunctionData = async () => {
    try {
      const response = await axios.get(
        BASE_URL +
          `SMDemo-EventLogByFunctionServiceLambda?startDateTime=${startTS}&endDateTime=${endTS}`
      );
      let jsonArray = response.data.fault;
      const updatedJsonArray = jsonArray.slice(0, 6).map((obj) => ({
        ...obj,
        label: obj?.key,
        color: getEventLogByFunctionLegends(obj.key),
      }));

      let settingjsonArray = response.data.setting;
      const updatedJsonArraySetting = settingjsonArray
        .slice(0, 6)
        .map((obj) => ({
          ...obj,
          label: obj?.key,
          color: getEventLogByFunctionLegends(obj.key),
        }));

      setData(updatedJsonArray);
      setSettingData(updatedJsonArraySetting);
      setispin(false);
      setError(false);
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        if (status >= 400 && status < 500) {
          setispin(false);
          setError(true);
        } else if (status >= 500 && status < 600) {
          setispin(false);
          setError(true);
        } else {
          setispin(false);
          setError(true);
        }
      } else {
        setError(true);
        setispin(false);
      }
    }
  };
  const [data, setData] = React.useState([
    { key: "Overcurrent", value: 10, label: "Overcurrent", color: "#FF9F8C" },
    {
      key: "Dir.earth-fault",
      value: 14,
      label: "Dir.earth-fault",
      color: "#5C0006",
    },
  ]);
  const [setttingData, setSettingData] = React.useState([
    {
      key: "Differential Current",
      value: 10,
      label: "Differential Current",
      color: "#FF9F8C",
    },
    {
      key: "Underfrequency",
      value: 14,
      label: "Underfrequency",
      color: "#5C0006",
    },
  ]);
  const [legendPosition, setLegendPosition] = React.useState("right");
  const [showLabel, setShowLabel] = React.useState(true);
  const [showValueText, setShowValueText] = React.useState(true);
  const [showDescription, setShowDescription] = React.useState(true);
  const [valueHiding, setValueHiding] = React.useState(true);
  const [enableHoverOnChart, setEnableHoverOnChart] = React.useState(true);
  const [enableHoverOnLegend, setEnableHoverOnLegend] = React.useState(true);
  const [customLegendTemplate, setCustomLegendTemplate] = React.useState(false);

  const [hiddenItems, setHiddenItems] = React.useState([]);
  const [hoverItem, setHoverItem] = React.useState();
  const [selectedValueSingle, setSelectedValueSingle] = React.useState(0);

  const formatLegendItem = (d) => (
    <div className="legend-scrol">
      <span>
        <span style={{ color: d.color }}>{d?.label}</span>
        <span style={{ fontSize: "80%" }}>, {d?.value}</span>
      </span>
    </div>
  );

  const dominantValue = data
    .slice()
    .filter((i) => hiddenItems.indexOf(i.key) === -1)
    .sort((a, b) => (a.value < b.value ? 1 : -1))[0];
  const dominantValueSetting = setttingData
    .slice()
    .filter((i) => hiddenItems.indexOf(i.key) === -1)
    .sort((a, b) => (a.value < b.value ? 1 : -1))[0];
  return (
    <>
      {isSpin ? (
        <Spinner
          style={{ margin: "auto" }}
          color="dark-grey"
          size="small"
        ></Spinner>
      ) : (
        <>
          {error ? (
            <span style={{ margin: "auto" }} color="dark-grey" size="small">
              {ERROR_LIST.ERROR_FETCH_DATA}
              <br />
              <Link
                style={{ color: "#1f1f1f" }}
                onClick={() => {
                  fetchEventLogsByFunctionData();
                }}
              >
                Retry
              </Link>
            </span>
          ) : (
            <>
              {data.length > 0 || setttingData.length > 0 ? (
                <div style={{ width: "100%" }}>
                  <div
                    className="eventlog_toggle"
                    style={{ float: "left", margin: "0px 0px" }}
                  >
                    <ToggleButtonGroup
                      monochrome="normal"
                      selected={selectedValueSingle == 0 ? "0" : "1"}
                      onChange={(v) => setSelectedValueSingle(v)}
                      size="small"
                      multiselect={false}
                    >
                      <ToggleButton value="Fault" text="Fault" />
                      <ToggleButton value="Setting" text="Setting" />
                    </ToggleButtonGroup>
                  </div>
                  <div>
                    {selectedValueSingle == 0 ? (
                      <DonutChart
                        className="eventlog"
                        style={{ width: "100%", padding: "0px 0px" }}
                        legendPosition={legendPosition}
                        allowValueHiding={valueHiding}
                        disableHoverOnChart={!enableHoverOnChart}
                        disableHoverOnLegend={!enableHoverOnLegend}
                        legendItemFormatter={
                          customLegendTemplate ? formatLegendItem : undefined
                        }
                        label={
                          !showLabel
                            ? undefined
                            : `${
                                hoverItem
                                  ? hoverItem.label
                                  : dominantValue?.label
                              }`
                        }
                        valueText={
                          !showValueText
                            ? undefined
                            : `${
                                hoverItem
                                  ? hoverItem.value
                                  : dominantValue?.value
                              }`
                        }
                        description={
                          showDescription && `${hoverItem ? " " : "Event(s)"}`
                        }
                        hiddenItems={hiddenItems}
                        onHiddenItemsChanged={(items) => setHiddenItems(items)}
                        onHover={(item) =>
                          setHoverItem(data.filter((i) => i.key === item)[0])
                        }
                        onBlur={() => setHoverItem(undefined)}
                        data={data}
                      />
                    ) : (
                      <div>
                        <DonutChart
                          className="eventlog"
                          style={{ width: "100%", padding: "0px 0px" }}
                          legendPosition={legendPosition}
                          allowValueHiding={valueHiding}
                          disableHoverOnChart={!enableHoverOnChart}
                          disableHoverOnLegend={!enableHoverOnLegend}
                          legendItemFormatter={
                            customLegendTemplate ? formatLegendItem : undefined
                          }
                          label={
                            !showLabel
                              ? undefined
                              : `${
                                  hoverItem
                                    ? hoverItem.label
                                    : dominantValueSetting?.label
                                }`
                          }
                          valueText={
                            !showValueText
                              ? undefined
                              : `${
                                  hoverItem
                                    ? hoverItem.value
                                    : dominantValueSetting?.value
                                }`
                          }
                          description={
                            showDescription && `${hoverItem ? " " : "Event(s)"}`
                          }
                          hiddenItems={hiddenItems}
                          onHiddenItemsChanged={(items) =>
                            setHiddenItems(items)
                          }
                          onHover={(item) =>
                            setHoverItem(data.filter((i) => i.key === item)[0])
                          }
                          onBlur={() => setHoverItem(undefined)}
                          data={setttingData}
                        />
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <p className="center-text-error">{ERROR_LIST.NO_EVENTS}</p>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}

export default EventLog;
