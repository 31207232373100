import React, { useEffect, useContext } from "react";
import axios from "axios";
import { TabItem, TabControl } from "@abb/abb-common-ux-react";
import "../net-zero/digital-twin.css";
import { RootContext } from "../../contexts/root-context";
import MainSwitchboard from "../../components/Net-Zero-Components/Digital-twin/main-switchboard";
import SldDiagram from "../../components/Net-Zero-Components/Digital-twin/sld-diagram";
import { NZ_BASE_URL } from "../../strings";

const initialTabs = [
  <TabItem key="1" title="Single Line Diagram">
    <SldDiagram />
  </TabItem>,
  <TabItem key="2" title="Power Distribution">
    <MainSwitchboard />
  </TabItem>,
];

function DigitalTwins() {
  const { setSpinner } = useContext(RootContext);
  const [tabs] = React.useState(initialTabs);
  const [activeTab, setActiveTab] = React.useState(0);
  const [type] = React.useState("secondary");
  const [monochrome] = React.useState(false);
  const { setDigitalTwinsData } = useContext(RootContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${NZ_BASE_URL}/digital_twin`);
        setDigitalTwinsData(response.data);
        setSpinner(false);
      } catch (error) {
        console.error("Error fetching digital twin data:", error);
        setSpinner(true);
      }
    };

    fetchData();
    const intervalId = setInterval(fetchData, 15 * 60 * 1000);

    return () => clearInterval(intervalId);
  }, [setSpinner, setDigitalTwinsData]);

  return (
    <div className="tabparent">
      <TabControl
        type={type}
        activeTab={activeTab}
        onTabChange={(oldIndex, newIndex) => setActiveTab(newIndex)}
        monochrome={monochrome}
        style={{ width: "80%" }}
      >
        {tabs}
      </TabControl>
    </div>
  );
}
export default DigitalTwins;
