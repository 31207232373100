import React, { useContext } from "react";
import { Gauge } from "@abb/abb-common-ux-react";
import "./co2-savings.css";
import { RootContext } from "../../../contexts/root-context";
function CO2ETons() {
  const { siteKpis } = useContext(RootContext);

  // bar 1
  const titleForCo2Estimate =
    siteKpis?.siteKPIMetrics?.co2Consumption?.target || " ";
  const valueForCo2Estimate =
    siteKpis?.siteKPIMetrics?.co2Consumption?.value || 1234;
  const maxCo2Consumption =
    siteKpis?.siteKPIMetrics?.co2Consumption?.target || 4200;
  const [progressBar1Value, setProgressBar1Value] =
    React.useState(valueForCo2Estimate);
  const [progressBar1Range, setProgressBar1Range] = React.useState([
    0,
    maxCo2Consumption,
  ]);

  // bar 2
  const titleForCo2ConsumptionLastYear =
    siteKpis?.siteKPIMetrics?.co2Consumption?.lastYear || " ";
  const lastYearCo2ConsumptionValue =
    siteKpis?.siteKPIMetrics?.co2Consumption?.lastYear || 5900;
  const lastYearCo2ConsumptionMax =
    siteKpis?.siteKPIMetrics?.co2Consumption?.target || 4200;
  const [progressBar2Value, setProgressBar2Value] = React.useState(
    lastYearCo2ConsumptionValue
  );
  const [progressBar2Range, setProgressBar2Range] = React.useState([
    0,
    lastYearCo2ConsumptionMax,
  ]);

  // measure
  const measureValue = siteKpis?.siteKPIMetrics?.co2Savings?.value || 1234;
  const measureKpi = siteKpis?.siteKPIMetrics?.co2Savings?.kpi || "+10% YoY";

  const [decimals, setDecimals] = React.useState(0);
  const [warnings, setWarnings] = React.useState([25, 75]);
  const [alarms, setAlarms] = React.useState([10, 90]);
  const [limitMarkerStyle, setLimitMarkerStyle] = React.useState("none");
  return (
    <div className="co2-saving-container">
      <div className="co2-saving-container-top-div">
        <Gauge
          style={{ width: "50%" }}
          type="bar"
          label="Target "
          min="0"
          max={progressBar1Range[1]}
          minLabel={0 + "%"}
          maxLabel={100 + "%"}
          value={progressBar1Value}
          formatValue={(val) => `${val})`}
        />
        <div style={{ width: "50%", marginTop: "4%", marginLeft: "1%" }}>
          <span>YTD</span>
          <div className="divider"></div>
        </div>
      </div>
      <div className="number">{measureValue}</div>
      <div className="co2-saving-container-bottom-div">
        <Gauge
          style={{ width: "50%" }}
          type="bar"
          label="Last Year  "
          min="0"
          max={progressBar2Range[1]}
          minLabel={0 + "%"}
          maxLabel={100 + "%"}
          value={progressBar2Value}
          formatValue={(val) => `${val})`}
        />
        <div style={{ width: "50%", marginTop: "35px", marginLeft: "1%" }}>
          <div className="divider"></div>
          <span>{measureKpi}</span>
        </div>
      </div>
    </div>
  );
}

export default CO2ETons;
