import React, { useEffect, useContext, useState } from "react";
import { Gauge, Spinner } from "@abb/abb-common-ux-react";
import axios from "axios";
import { RootContext } from "../../contexts/root-context";
import "./feeder-measurement.css";
import { BASE_URL } from "../../strings";
function FeederMeasurement() {
  const { setSpinner, startTS, endTS } = useContext(RootContext);
  const [feederMeasurementResponse, setfeederMeasurementResponse] = useState(
    {}
  );
  const [isSpin, setispin] = useState(true);
  useEffect(() => {
    setSpinner(true);

    let getFeederMeasurementData = [
      {
        startDateTime: "03/14/2023 00:00:00",
        endDateTime: "03/14/2023 18:25:00",
      },
    ];
    const headers = {
      "Content-Type": "Application/Json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET",
    };

    axios
      .get(
        BASE_URL +
          `SMDemo-FeederMeasurementServiceLambda?startDateTime=${startTS}&endDateTime=${endTS}`
      )
      .then((response) => {
      
        setfeederMeasurementResponse(response.data);
        setSpinner(false);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setispin(false);
      });
  }, [startTS]);
  return (
    <div className="component-container">
      {isSpin ? (
        <Spinner style={{ margin: "24%" }} color="dark-grey" size="small" />
      ) : (
        <>
          <Gauge
            type="bar"
            label="Voltage"
            min="0"
            max={parseFloat(feederMeasurementResponse.maxVoltageRange)}
            minLabel={0 + "%"}
            maxLabel={100 + "%"}
            className="feeder-component"
            // label="Active Power"
            unit={feederMeasurementResponse.voltageUnit}
            value={parseFloat(feederMeasurementResponse.voltage)}
            decimals={true}
          />
          <Gauge
            type="bar"
            label="Current"
            min="0"
            max={parseFloat(feederMeasurementResponse.maxCurrentRange)}
            minLabel={0 + "%"}
            maxLabel={100 + "%"}
            className="feeder-component"
            // label="Active Power"
            unit={feederMeasurementResponse.currentUnit}
            value={parseFloat(feederMeasurementResponse.current)}
            decimals={true}
          />
          <Gauge
            type="bar"
            label="Active Energy"
            min="0"
            max={parseFloat(feederMeasurementResponse.maxActiveEnergyRange)}
            minLabel={0 + "%"}
            maxLabel={100 + "%"}
            className="feeder-component"
            // label="Active Power"
            unit={feederMeasurementResponse.energyUnit}
            value={parseFloat(feederMeasurementResponse.activeEnergy)}
            decimals={true}
          />
        </>
      )}
    </div>
  );
}

export default FeederMeasurement;
