import React, { useContext, useEffect, useRef, useState } from "react";
import * as echarts from "echarts";
import { RootContext } from "../../../contexts/root-context";
import "./energy-mix-forecast.css";
import { Spinner } from "@abb/abb-common-ux-react";
import { NZ_ERROR_LIST } from "../../../strings";

function EnergyMixForecast() {
  const { forecastData, energyMixForecastSelectedDuration } =
    useContext(RootContext);

  const [data, setData] = useState([]);
  const chartRef = useRef(null);
  const [isSpin, setIsSpin] = useState(true);

  // Assuming 'Time', 'Utility', 'Solar' as the default columns
  // const columns = ["Time", "Utility", "Solar"];

  useEffect(() => {
    const forcastedResultSolar = forecastData.find(
      (forecast) => forecast.name === "solar"
    )?.forecast;

    const forcastedResultUtility = forecastData.find(
      (forecast) => forecast.name === "utility"
    )?.forecast;

    if (!forcastedResultSolar || !forcastedResultUtility) {
      return;
    }

    // Update chart data based on selected duration
    updateEnergyMixForecastChartData(
      forcastedResultSolar,
      forcastedResultUtility
    );
  }, [forecastData, energyMixForecastSelectedDuration]);

  // Function to update chart data based on selected duration
  const updateEnergyMixForecastChartData = (
    forcastedResultSolar,
    forcastedResultUtility
  ) => {
    if (!forecastData || forecastData.length === 0) {
      setIsSpin(true);
      return;
    }

    let result;

    if (energyMixForecastSelectedDuration === "Monthly") {
      result = convertEnergyForecastToChartDataMonth(
        forcastedResultSolar,
        forcastedResultUtility
      );
    } else {
      result = convertEnergyForecastToChartDataDay(
        forcastedResultSolar,
        forcastedResultUtility
      );
    }

    if (result) {
      setData(result);
      setIsSpin(false);
    }
  };

  // Function to convert monthly energy forecast data to chart data
  const convertEnergyForecastToChartDataMonth = (
    forecastResultSolar,
    forecastResultUtility
  ) => {
    const solarPredictionsByMonthP50 = forecastResultSolar.Predictions.p50.map(
      (forecast) => getObjectFromForecastWithMonth(forecast)
    );
    const utilityPredictionsByMonthP50 =
      forecastResultUtility.Predictions.p50.map((forecast) =>
        getObjectFromForecastWithMonth(forecast)
      );

    const solarValuesByMonthP50 = groupValuesByMonth(
      solarPredictionsByMonthP50
    );
    const utilityValuesByMonthP50 = groupValuesByMonth(
      utilityPredictionsByMonthP50
    );

    let result = [];
    for (const key of Object.keys(solarValuesByMonthP50)) {
      result.push([
        key,
        utilityValuesByMonthP50[key],
        solarValuesByMonthP50[key],
      ]);
    }
    return result;
  };

  // Function to convert daily energy forecast data to chart data
  const convertEnergyForecastToChartDataDay = (
    forecastResultSolar,
    forecastResultUtility
  ) => {
    const solarPredictions = forecastResultSolar.Predictions.p50.map(
      (forecast) => getObjectFromForecastWithDay(forecast)
    );
    const utilityPredictions = forecastResultUtility.Predictions.p50.map(
      (forecast) => getObjectFromForecastWithDay(forecast)
    );

    let result = [];
    for (let i = 0; i < solarPredictions.length; i++) {
      result.push([
        formatDate(solarPredictions[i].date),
        utilityPredictions[i].value,
        solarPredictions[i].value,
      ]);
    }
    return result;
  };

  // Function to format date to 'dd-mm' format
  const formatDate = (date) => {
    const d = new Date(date);
    const day = d.getDate().toString().padStart(2, "0");
    const month = (d.getMonth() + 1).toString().padStart(2, "0");
    return `${day}-${month}`;
  };

  // Function to format y-axis labels (e.g., 1000 to '1k')
  const formatYAxisLabel = (value) => {
    if (Math.abs(value) >= 1000) {
      return (value / 1000).toFixed(0) + "k";
    }
    return value;
  };

  // Function to group values by month
  const groupValuesByMonth = (predictions) => {
    const valuesByMonth = {};
    for (let prediction of predictions) {
      if (prediction.month in valuesByMonth) {
        valuesByMonth[prediction.month] += prediction.value;
      } else {
        valuesByMonth[prediction.month] = prediction.value;
      }
    }
    return valuesByMonth;
  };

  // Function to get object from forecast with month
  const getObjectFromForecastWithMonth = (forecast) => {
    const ts = new Date(forecast.Timestamp);
    return {
      month: ts.toLocaleString("en-US", { month: "short" }),
      value: Math.round(forecast.Value),
    };
  };

  // Function to get object from forecast with day
  const getObjectFromForecastWithDay = (forecast) => {
    const ts = new Date(forecast.Timestamp);
    return {
      date: ts,
      value: Math.round(forecast.Value),
    };
  };

  useEffect(() => {
    if (!data || data.length === 0) {
      return;
    }

    const chart = echarts?.init(chartRef?.current);

    const option = {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },
      legend: {
        orient: "horizontal",
        top: "bottom",
      },
      xAxis: {
        type: "category",
        data: data?.map((item) => item[0]),
        axisTick: {
          alignWithLabel: true,
        },
        axisLabel: {
          padding: 0,
        },
      },
      yAxis: {
        type: "value",
        axisLabel: {
          padding: 0,
          verticalAlign: "top",
          lineHeight: 10,
          formatter: formatYAxisLabel, // Format y-axis labels (e.g., 1000 to '1k')
        },
        name: "Energy (kWh)",
        nameLocation: "center",
        nameGap: 35,
      },
      series: [
        {
          name: "Utility",
          type: "bar",
          data: data?.map((item) => item[1]),
          itemStyle: {
            color: "#808080",
          },
          stack: "x",
        },
        {
          name: "Solar",
          type: "bar",
          data: data?.map((item) => item[2]),
          itemStyle: {
            color: "#008000",
          },
          stack: "x",
        },
      ],
    };

    chart?.setOption(option);
    window.addEventListener("resize", chart.resize);

    return () => {
      window.removeEventListener("resize", chart.resize);
      chart.dispose();
    };
  }, [data]);

  return (
    <>
      {isSpin ? (
        <Spinner
          style={{ margin: "auto" }}
          color="dark-grey"
          size="small"
        ></Spinner>
      ) : (
        <>
          {data?.length > 0 ? (
            <div className="energy-mix-forecast-container">
              <div
                ref={chartRef}
                // style={{ width: "100%", height: "100%" }}
                className="energy-mix-forecast-resizable-chart"
              />
            </div>
          ) : (
            <p className="center-text-error">{NZ_ERROR_LIST.NO_DATA}</p>
          )}
        </>
      )}
    </>
  );
}

export default EnergyMixForecast;
