import React, { useContext, useEffect, useState } from "react";
import NetZeroCard from "../../components/Net-Zero-Components/Net-Zero-Card/net-zero-card";
import { SITE_KPIS_CARD_JSON } from "../../strings";
import "./site-kpis.css";
import axios from "axios";
import { RootContext } from "../../contexts/root-context";

function SiteKPIs() {
  const { siteKpis, setSiteKpis } = useContext(RootContext);
  const [data, setData] = useState({});
  const [isSpin, setispin] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    fetchSiteKPIsData();
    const interval = setInterval(() => {
      fetchSiteKPIsData();
    }, 15 * 60 * 1000);

    return () => clearInterval(interval);
  }, []);

  const fetchSiteKPIsData = async () => {
    setispin(true);
    try {
      const response = await axios.get(
        "https://kstm312sba.execute-api.us-east-1.amazonaws.com/api/kpi",
        {
          "Content-Type": "application/json",
          Connection: "keep-alive",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods":
            "GET, POST, PATCH, PUT, DELETE, OPTIONS",
          "Accept-Language": "en-US",
        }
      );
      setData(response.data);
      setSiteKpis(response.data);
      setispin(false);
      setError(false);
    } catch (error) {
      setError(true);
      setispin(false);
    }
  };
  const siteName = data?.siteMetadata?.siteName || "ABB BUILDING 1";
  return (
    <div className="kpi-container">
      <div className="kpi-left-div">
        {SITE_KPIS_CARD_JSON.slice(0, 1).map((widget, index) => (
          <NetZeroCard
            key={index}
            widgetName={siteName ?? widget.widgetName}
            widgetType={widget.widgetType}
            nzcomponent={widget.nzcomponent}
          />
        ))}
        {SITE_KPIS_CARD_JSON.slice(1, 9).map((widget, index) => (
          <NetZeroCard
            key={index + 1}
            widgetName={widget.widgetName}
            widgetType={widget.widgetType}
            nzcomponent={widget.nzcomponent}
          />
        ))}
      </div>
      <div className="kpi-right-div">
        {SITE_KPIS_CARD_JSON.slice(9).map((widget, index) => (
          <NetZeroCard
            key={index + 9}
            widgetName={widget.widgetName}
            widgetType={widget.widgetType}
            nzcomponent={widget.nzcomponent}
          />
        ))}
      </div>
    </div>
  );
}

export default SiteKPIs;
