import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import * as echarts from 'echarts';
import "./battery-soc.css";
import { fetchCharts } from '../../api';
import { EMCORE_DASHBOARD_BASE_API_URL } from '../../../../strings';
import { RootContext } from '../../../../contexts/root-context';
import { getEMCoreFilteredData } from '../../../../util';

const Battery_SoC_1 = () => {
    const chartRef = useRef(null);
    const [loadBatterySocData, setloadBatterySocData] = useState([]);
    const [originalData,setoriginalData]=useState([]);
    const { startTS, endTS } = useContext(RootContext);
    useEffect(()=>{
        // When filter applied
        const filteredLoads=getEMCoreFilteredData(originalData,startTS,endTS)
        const truncatedData = filteredLoads.map(item => ({
            ...item,
            timestamp: item?.timestamp?.split(" ")[1].slice(0,5)  // Extracts the date part only
        }));
       
        setloadBatterySocData(truncatedData);
     },[startTS, endTS])

    // Fetch load profile data with useCallback
    const fetchBatterySoc = useCallback(async () => {
        try {
            const batteryResponse = await fetchCharts(`${EMCORE_DASHBOARD_BASE_API_URL}/battery_soc_battery1.json`, {});
            setoriginalData(batteryResponse)
            const truncatedData = batteryResponse.map(item => ({
                ...item,
                timestamp: item?.timestamp?.split(" ")[1].slice(0,5)  // Extracts the date part only
            }));
            setloadBatterySocData(truncatedData); // Assuming loadresponse is an array of data
            // console.log("battery json", batteryResponse);
        } catch (error) {
            console.error('Error fetching the JSON data:', error);
        }
    }, []);

    // Fetch data on mount
    useEffect(() => {
        fetchBatterySoc();
    }, [fetchBatterySoc]);

    // Update chart whenever loadData changes
    useEffect(() => {
        if (!loadBatterySocData.length) return; // Only initialize the chart if data is available

        const chart = echarts.init(chartRef.current);

        const option = {
            tooltip: {
                trigger: 'axis'
            },
            xAxis: {
                type: 'category',
                data: loadBatterySocData.map(item => item.timestamp), // Assuming 'timestamp' is part of your data
                name: 'Time',
                nameLocation: 'middle',
                nameGap: 35,
                axisTick: {
                    alignWithLabel: true
                },
                axisLabel: {
                    padding: [4,-20,4,4],
                    interval: 20,
                },
            },
            


            yAxis: {
                type: 'value',
                name: 'SoC (KWh)',
                nameLocation: 'center',
                nameRotate: 90,
                nameGap: 20,
                axisLabel: {
                    padding: -15,
                    verticalAlign: 'top',
                    lineHeight: 10,
                },
                interval: 50, // Set interval between y-axis values
            },
            grid: {
                left: '8%', // Adjust the grid to ensure y-axis labels don't get cut off
                right: '10%',
                bottom: '23%', // Leave room for tilted x-axis labels
                top: '13%',
            },
            // legend: {
            //     data: ['Battery Soc'], // Add legend items
            //     top: '5%', // Position legend at the top
            //     icon: 'rect'
            // },
            series: [
                {
                    name: 'Battery Soc 1', 
                    type: 'line',
                    data: loadBatterySocData.map(item => item["battery-soc"]), // Assuming 'price' is part of your data
                    smooth: true,
                    showSymbol: false,
                    itemStyle: {
                        color: '#E12E2E' // Set line color for Active Power
                    },
                },
                // You can uncomment this if there's additional series data:
                // {
                //     name: 'Actual Load', // Name for the second legend item
                //     type: 'line',
                //     data: loadData.map(item => item.value), // Assuming 'value' is part of your data
                //     smooth: true,
                //     showSymbol: false,
                //     itemStyle: {
                //         color: '#2E8B57' // Set line color for Reactive Power
                //     },
                // }
            ]
        };

        chart.setOption(option);

        return () => {
            chart.dispose();
        };
    }, [loadBatterySocData]); // Update the chart when loadData changes

    return (
        <div className='battery-soc-chart-container'>
            <div ref={chartRef} className='battery-soc-chart' />
        </div>
    );
};

export default Battery_SoC_1;
