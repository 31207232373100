import React from "react";
// import Navbar from "../../components/Navbar/navbar";

import Welcome from "../../components/proofer-tool-cmps/welcome/welcome";

function ProoferTool() {
    return (
        <>
            <Welcome />
        </>
    );
}

export default ProoferTool;
