import { Table } from "@abb/abb-common-ux-react";
import React, { useContext } from "react";
import "./site-information.css";
import { RootContext } from "../../../contexts/root-context";

function SiteInformation() {
  const { siteKpis } = useContext(RootContext);
  const contractualPower =
    siteKpis?.siteMetadata?.siteContractDetails?.contractualPower || "350kW"; // taking intital values
  const maxOccupants =
    siteKpis?.siteMetadata?.siteContractDetails?.maxOccupants || "300";
  const groupData = [
    "SIZE",
    "CONTRACTUAL POWER",
    "MAX OCCUPANTS",
    "SOLAR ROOF",
    "EV CHARGERS",
    "ELEVATORS",
  ];
  // borderType = none
  const sortedData = [
    "15000 sqm",
    contractualPower,
    maxOccupants,
    "50Kw",
    "4",
    "3",
  ];
  return (
    <div
      className="margins borders padding"
      style={{ height: "200px", width: "90%", background: "inherit" }}
    >
      <div style={{ height: "100%", overflow: "auto", background: "inherit" }}>
        <Table
          sizeClass={"large"} // small, medium, large
          solidBackground={false}
          borderType={"discreet"} // none, normal
          zebra={false}
          scrollableBody={true}
        >
          <tbody style={{ pointer: "cursor" }}>
            {groupData.map((groupItem, index) => (
              <tr key={index}>
                <td className="siteInfoName">{groupItem}</td>
                <td className="siteInfoValue" style={{ textAlign: "right" }}>
                  <span>{sortedData[index]}</span>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
}

export default SiteInformation;
