import React from "react";
import { Gauge } from "@abb/abb-common-ux-react";

import "./ev-chargers.css";
import { useEffect } from "react";
import { useContext } from "react";
import { RootContext } from "../../../contexts/root-context";

function EVChargers() {
  const { siteKpis } = useContext(RootContext);

  const evChargerValue = siteKpis?.siteKPIMetrics?.emobility?.value;
  const [value, setValue] = React.useState(evChargerValue || 1250);
  const [range, setRange] = React.useState([0, 100]);
  const [decimals, setDecimals] = React.useState(0);
  const [warnings, setWarnings] = React.useState([25, 75]);
  const [alarms, setAlarms] = React.useState([10, 90]);
  const [limitMarkerStyle, setLimitMarkerStyle] = React.useState("none");
  // Update value state when valueForSolarTarget changes
  useEffect(() => {
    if (evChargerValue !== undefined) {
      setValue(evChargerValue);
    }
  }, [evChargerValue]);
  return (
    <div
      className="kpi-gauge-content"
      style={{
        width: "100%",
      }}
    >
      <Gauge
        className="nz-gauge-style"
        type="radial"
        min={range[0]}
        max={range[1]}
        minLabel={" "}
        maxLabel={" "}
        lowAlarm={alarms[0]}
        highAlarm={alarms[1]}
        lowWarn={warnings[0]}
        highWarn={warnings[1]}
        unit="kWh"
        value={value}
        limitStyle={limitMarkerStyle}
        decimals={decimals}
      />
    </div>
  );
}

export default EVChargers;
