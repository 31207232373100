import React, { useState, useEffect } from 'react';
import { Dialog, ThemeToggler, LoginScreen } from "@abb/abb-common-ux-react";
import "./welcome.css";
import ProoferDashboard from '../../../pages/proofer-pages/dashboard/dashboard';
import UserSession from "../../../components/Authentication/user-session";
import Navbar from '../../Navbar/navbar';

const Welcome = () => {
    const [loading, setLoading] = useState(true);
    const [monochrome, setMonochrome] = useState(false);
    const [showLogo, setShowLogo] = useState(true);
    const [showDialog, setShowDialog] = useState(true); // State to control dialog visibility

    useEffect(() => {
        // Unmount the dialog after 5 seconds
        const timer = setTimeout(() => {
            setShowDialog(false);
        }, 5000);

        return () => clearTimeout(timer); // Cleanup the timer
    }, []);

    return (
        // <div className="margins shadows browser-reset" id="welcome-screen" >
        <>
            <UserSession />

            {showDialog ? (
                <>
                    <Navbar />
                    <Dialog disableDefaultStyle={true} id='welcome-screen'>
                        <LoginScreen
                            className="ABB_CommonUX_LoginScreen__root ABB_CommonUX_LoginScreen__dark ABB_CommonUX_LoginScreen__loading"
                            productName="Proofer Tool"
                            productSubName="Our Proof for Quick & Precise Offers."
                            loading={loading}
                            monochrome={monochrome}
                            showLogo={showLogo}
                            showDefaultFields={false}
                            showLoginButton={false}
                        />
                    </Dialog>
                </>
            ) : (


                < ProoferDashboard />

            )}
        </>
        /* </div> */
    );
};

export default Welcome;
