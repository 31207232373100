import React, { useContext, useEffect, useRef, useState } from "react";
import * as echarts from "echarts";
import { RootContext } from "../../../contexts/root-context";
import "./power-flow.css";
import { Spinner } from "@abb/abb-common-ux-react";

const PowerFlow = () => {
  const {
    powerTrendsData,
    powerFlowSelectedOption,
    setPowerFlowSelectedOption,
  } = useContext(RootContext);

  const powerFlow = powerTrendsData?.power_flow || [];
  const chartRef = useRef(null);
  const [data, setData] = useState([]);
  const [error, setError] = useState(false);
  const [isSpin, setIsSpin] = useState(true);

  useEffect(() => {
    // Ensure powerFlowSelectedOption is initially set to ["Solar Generated Power"]
    if (powerFlowSelectedOption.length === 0) {
      setPowerFlowSelectedOption(["Solar Generated Power"]);
    }
  }, [powerFlowSelectedOption, setPowerFlowSelectedOption]);

  useEffect(() => {
    updatePowerFlowChartData(powerFlowSelectedOption);
  }, [powerFlowSelectedOption]);

  const updatePowerFlowChartData = (selectedAssetNames) => {
    const powerFlowData = powerFlow.find((item) => item.name === "day");
    if (!powerFlowData) {
      setData([]);
      setError(true);
      setIsSpin(true);
      return;
    }

    const filteredData = getMatchingValues(
      powerFlowData.value,
      selectedAssetNames
    );
    setData(filteredData);
    setIsSpin(false);
    setError(false);
  };

  function getMatchingValues(objects, labels) {
    return objects?.filter((obj) => labels.includes(obj?.assetName)) ?? [];
  }

  useEffect(() => {
    if (!chartRef.current || data.length === 0) return;

    const chart = echarts?.init(chartRef?.current);

    const series = powerFlowSelectedOption.map((option, index) => ({
      name: option,
      type: "line",
      smooth: true,
      emphasis: {
        focus: "series",
      },
      data: data
        .filter((item) => item.assetName === option)
        .map((item) => item.value),
      itemStyle: {
        color: getLineColor(index),
      },
    }));

    const option = {
      tooltip: {
        trigger: "axis",
      },
      legend: {
        data: series.map((s) => s.name),
        orient: "horizontal",
        top: "bottom",
      },
      xAxis: {
        type: "category",
        data: data?.map((item) => item.datetime.substring(11, 16)),
        axisTick: {
          alignWithLabel: true,
        },
        axisLabel: {
          padding: 0,
        },
      },
      yAxis: {
        type: "value",
        axisLabel: {
          padding: 0,
          verticalAlign: "top",
          lineHeight: 10,
        },
      },
      series: series,
    };

    chart.setOption(option);
    window.addEventListener("resize", chart.resize);

    return () => {
      window.removeEventListener("resize", chart.resize);
      chart.dispose();
    };
  }, [data, powerFlowSelectedOption]);

  useEffect(() => {
    if (powerTrendsData && powerTrendsData.power_flow) {
      const defaultOption = ["Solar Generated Power"];
      updatePowerFlowChartData(defaultOption);
    }
  }, [powerTrendsData]);

  const getLineColor = (index) => {
    const colors = [
      "#3366cc", // Royal blue
      "#dc3912", // Red-orange
      "#ff9900", // Bright orange
      "#109618", // Green
      "#990099", // Violet
      "#0099c6", // Cyan
      "#dd4477", // Pink
      "#66aa00", // Lime green
      "#b82e2e", // Dark red
      "#316395", // Navy blue
      "#994499", // Purple
      "#22aa99", // Aqua
      "#aaaa11", // Yellowish green
      "#6633cc", // Deep purple
      "#e67300", // Bright orange
      "#8b0707", // Dark red
      "#651067", // Dark purple
      "#329262", // Teal
      "#5574a6", // Steel blue
    ];

    return colors[index % colors.length];
  };

  // return (
  //   <div className="power-flow-container">
  //     <div ref={chartRef} style={{ width: "100%", height: "100%" }} />
  //   </div>
  // );

  return (
    <>
      {isSpin ? (
        <Spinner
          style={{ margin: "auto" }}
          color="dark-grey"
          size="small"
        ></Spinner>
      ) : (
        <>
          {data?.length > 0 ? (
            <div className="power-flow-container">
              <div
                ref={chartRef}
                // style={{ width: "100%", height: "100%" }}
                className="power-flow-resizable-chart"
              />
            </div>
          ) : (
            <p className="center-text-error"></p>
          )}
        </>
      )}
    </>
  );
};

export default PowerFlow;
