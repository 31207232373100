import React, { useContext, useState } from "react";
import { DonutChart, Link, Spinner } from "@abb/abb-common-ux-react";
import axios from "axios";
import { RootContext } from "../../contexts/root-context";
import { BASE_URL, ERROR_LIST } from "../../strings";
import { getFaultDurationLegendsColor } from "../../util";

function FaultDuration() {
  const { startTS, endTS } = useContext(RootContext);
  const [error, setError] = useState(false);
  const [isSpin, setispin] = useState(true);
  const [data, setData] = React.useState([
    { key: "", value: 25, label: "2-3 seconds", color: "#FF9F8C" },
    { value: 20, label: ">10 seconds", color: "#5C0006" },
  ]);

  React.useEffect(() => {
    fetchFaultDurationData();
  }, [startTS]);

  let fetchFaultDurationData = async () => {
    setispin(true);
    try {
      const response = await axios.get(
        BASE_URL +
          `SMDemo-FaultDurationServiceLambda?startDateTime=${startTS}&endDateTime=${endTS}`
      );
      let jsonArray = response.data.response;
      const updatedJsonArray = jsonArray.map((obj) => ({
        ...obj,
        label: obj?.key !== "unknown" ? obj?.key : "",
        color: getFaultDurationLegendsColor(obj.key),
      }));

      setData(updatedJsonArray);
      setispin(false);
      setError(false);
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        if (status >= 400 && status < 500) {
          setispin(false);
          setError(true);
        } else if (status >= 500 && status < 600) {
          setispin(false);
          setError(true);
        } else {
          setispin(false);
          setError(true);
        }
      } else {
        setError(true);
        setispin(false);
      }
    }
  };

  const [legendPosition, setLegendPosition] = React.useState("right");
  const [showLabel, setShowLabel] = React.useState(true);
  const [showValueText, setShowValueText] = React.useState(true);
  const [showDescription, setShowDescription] = React.useState(true);
  const [valueHiding, setValueHiding] = React.useState(true);
  const [enableHoverOnChart, setEnableHoverOnChart] = React.useState(true);
  const [enableHoverOnLegend, setEnableHoverOnLegend] = React.useState(true);
  const [customLegendTemplate, setCustomLegendTemplate] = React.useState(false);
  const [hiddenItems, setHiddenItems] = React.useState([]);
  const [hoverItem, setHoverItem] = React.useState();

  const formatLegendItem = (d) => (
    <span>
      <span style={{ color: d.color }}>{d?.label}</span>
      <span style={{ fontSize: "80%" }}>{d?.value}</span>
    </span>
  );

  const dominantValue = data
    .slice()
    .filter((i) => hiddenItems.indexOf(i.key) === -1)
    .sort((a, b) => (a.value < b.value ? 1 : -1))[0];

  return (
    <>
      {isSpin ? (
        <Spinner style={{ margin: "auto" }} color="dark-grey" size="small" />
      ) : (
        <>
          {error ? (
            <span style={{ margin: "auto" }} color="dark-grey" size="small">
              {ERROR_LIST.ERROR_FETCH_DATA}
              <br />
              <Link
                style={{ color: "#1f1f1f" }}
                onClick={() => {
                  fetchFaultDurationData();
                }}
              >
                Retry
              </Link>
            </span>
          ) : (
            <>
              {data.length > 0 ? (
                <DonutChart
                  style={{
                    width: "100%",
                    padding: "20px 0",
                    margin: "0px 20px",
                  }}
                  legendPosition={legendPosition}
                  allowValueHiding={valueHiding}
                  disableHoverOnChart={!enableHoverOnChart}
                  disableHoverOnLegend={!enableHoverOnLegend}
                  legendItemFormatter={
                    customLegendTemplate ? formatLegendItem : undefined
                  }
                  label={
                    !showLabel
                      ? undefined
                      : `${hoverItem ? hoverItem.label : dominantValue?.label}`
                  }
                  valueText={
                    !showValueText
                      ? undefined
                      : `${hoverItem ? hoverItem.value : dominantValue?.value}`
                  }
                  description={
                    showDescription && `${hoverItem ? " " : "Event(s)"}`
                  }
                  hiddenItems={hiddenItems}
                  onHiddenItemsChanged={(items) => setHiddenItems(items)}
                  onHover={(item) =>
                    setHoverItem(data.filter((i) => i.key === item)[0])
                  }
                  onBlur={() => setHoverItem(undefined)}
                  data={data}
                />
              ) : (
                <p className="center-text-error">{ERROR_LIST.NO_EVENTS}</p>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}

export default FaultDuration;
