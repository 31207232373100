import React, { useEffect, useState, useContext } from "react";
import "./disturbance-events.css";
import { RootContext } from "../../contexts/root-context";
import axios from "axios";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Label,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import { BASE_URL, ERROR_LIST } from "../../strings";
import { Spinner, Link } from "@abb/abb-common-ux-react";

function DisturbanceEvents() {
  const { setSpinner, startTS, endTS } = useContext(RootContext); //setSpinner is used to show the spinner when the page is loading
  const [isSpin, setispin] = useState(true);
  const [error, setError] = useState(false);
  React.useEffect(() => {
    fetchDistEvents();
  }, [startTS]);
  const fetchDistEvents = async () => {
    setispin(true);
    try {
      const response = await axios.get(
        BASE_URL +
        `SMDemo-DisturbanceEventsServiceLambda?startDateTime=${startTS}&endDateTime=${endTS}`
      );
      let jsonArray = response.data.responseList;
      console.log("Dist Events", jsonArray);
      const updatedJsonArray = jsonArray.map((obj) => ({
        ...obj,
        Acknowledged: obj?.ack,
        Unacknowledged: obj?.unAck,
        startDate: obj?.startDate?.split("T")[1],
        // startDate: obj?.startDate
        // startDate: obj?.startDate?.split("2023-")[1],
      }));
      setData(updatedJsonArray);
      setError(false);
      setispin(false);
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        if (status >= 400 && status < 500) {
          setispin(false);
          setError(true);
        } else if (status >= 500 && status < 600) {
          setispin(false);
          setError(true);
        } else {
          setispin(false);
          setError(true);
        }
      } else {
        setError(true);
        setispin(false);
      }
    }
  };
  const [data, setData] = React.useState([]);

  return (
    <>
      {isSpin ? (
        <Spinner
          style={{ margin: "auto" }}
          color="dark-grey"
          size="small"
        ></Spinner>
      ) : (
        <>
          {error ? (
            <span style={{ margin: "auto" }} color="dark-grey" size="small">
              {ERROR_LIST.ERROR_FETCH_DATA}
              <br />
              <Link
                style={{ color: "#1f1f1f" }}
                onClick={() => {
                  fetchDistEvents();
                }}
              >
                Retry
              </Link>
            </span>
          ) : (
            <>
              {data.length > 0 ? (
                <BarChart
                  id="dist-events-bar"
                  barCategoryGap={20}
                  barGap={3}
                  width={400}
                  height={220}
                  data={data}
                  margin={{ top: 15, right: 20, left: 10, bottom: 5 }}
                >
                  <CartesianGrid stroke={"false"} />
                  <XAxis dataKey="startDate">
                    <Label value="Time" offset={0} position="insideBottom" />
                  </XAxis>
                  <YAxis
                    label={{
                      value: "Fault Occurence",
                      angle: -90,
                      position: "insideLeft",
                    }}
                  />
                  <Tooltip />
                  <Legend
                    verticalAlign="top"
                    formatter={(value, entry, index) => (
                      <span className="text-color-class">{value}</span>
                    )}
                  />
                  <Bar dataKey="Acknowledged" fill="#F2AC66" />
                  <Bar dataKey="Unacknowledged" fill="#FF4754" />
                </BarChart>
              ) : (
                <p className="center-text-error">{ERROR_LIST.NO_EVENTS}</p>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}

export default DisturbanceEvents;
