import React, { useEffect, useState } from "react";
import { DonutChart, Spinner, Link } from "@abb/abb-common-ux-react";
import { ERROR_LIST } from "../../../strings";
import axios from "axios";
import "./co2-emission.css";

function Co2Emission() {
  const [isSpin, setispin] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchCo2EmissionData();
    const interval = setInterval(() => {
      fetchCo2EmissionData();
    }, 15 * 60 * 1000);

    return () => clearInterval(interval);
  }, []);

  const fetchCo2EmissionData = async () => {
    setispin(true);
    try {
      const response = await axios.get(
        "https://kstm312sba.execute-api.us-east-1.amazonaws.com/api/multisite_analysis",
        {
          "Content-Type": "application/json",
          Connection: "keep-alive",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods":
            "GET, POST, PATCH, PUT, DELETE, OPTIONS",
          "Accept-Language": "en-US",
        }
      );
      setData(response.data);
      setispin(false);
      setError(false);
    } catch (error) {
      setError(true);
      setispin(false);
    }
  };

  const totalCO2 = data.reduce((acc, building) => acc + building.co2, 0);
  const formattedData = data.map((building, index) => {
    const co2Percentage = ((building.co2 / totalCO2) * 100).toFixed(1);
    return {
      key: building.name,
      value: co2Percentage,
      label: building.name,
      color: index === 0 ? "#0097a7" : index === 1 ? "#ff5252" : "#7cb342",
      co2: building.co2.toFixed(2), // Include CO2 value for each building
    };
  });

  const [legendPosition] = useState("right");
  const [showLabel] = useState(true);
  const [showValueText] = useState(true);
  const [showDescription] = useState(true);
  const [valueHiding] = useState(true);
  const [enableHoverOnChart] = useState(true);
  const [enableHoverOnLegend] = useState(true);
  const [customLegendTemplate] = useState(false);
  const [hiddenItems, setHiddenItems] = useState([]);
  const [hoverItem, setHoverItem] = useState();

  const formatLegendItem = (d) => (
    <span>
      <span style={{ fontSize: "80%" }}> {d?.value}</span>
      <span style={{ color: d.color }}>{d?.label}</span>
    </span>
  );

  const dominantValue = formattedData
    .slice()
    .filter((i) => hiddenItems.indexOf(i.key) === -1)
    .sort((a, b) => (a.value < b.value ? 1 : -1))[0];

  return (
    <>
      {isSpin ? (
        <Spinner
          style={{ margin: "auto" }}
          color="dark-grey"
          size="small"
        ></Spinner>
      ) : (
        <>
          {error ? (
            <span style={{ margin: "auto" }} color="dark-grey" size="small">
              {ERROR_LIST.ERROR_FETCH_DATA}
              <br />
              <Link style={{ color: "#1f1f1f" }} onClick={fetchCo2EmissionData}>
                Retry
              </Link>
            </span>
          ) : (
            <>
              {data?.length > 0 ? (
                <div style={{ minHeight: "200px" }}>
                  <div
                    className="resizable"
                    style={{
                      width: "100%",
                      height: "100%",
                      minWidth: "200px",
                      minHeight: "100px",
                      // border: "1px dashed red",
                    }}
                  >
                    <DonutChart
                      style={{
                        width: "100%",
                        height: "100%",
                        paddingTop: "8px",
                      }}
                      legendPosition={legendPosition}
                      allowValueHiding={valueHiding}
                      disableHoverOnChart={!enableHoverOnChart}
                      disableHoverOnLegend={!enableHoverOnLegend}
                      legendItemFormatter={
                        customLegendTemplate ? formatLegendItem : undefined
                      }
                      label={
                        !showLabel
                          ? undefined
                          : `${hoverItem ? hoverItem.label : dominantValue.label
                          }`
                      }
                      valueText={
                        !showValueText
                          ? undefined
                          : `${hoverItem ? hoverItem.value : dominantValue.value
                          }%`
                      }
                      // did some changes for rendering the co2 emission data
                      description={
                        showDescription &&
                        `${hoverItem ? hoverItem.co2 : dominantValue.co2}`
                      }
                      hiddenItems={hiddenItems}
                      onHiddenItemsChanged={(items) => setHiddenItems(items)}
                      onHover={(item) =>
                        setHoverItem(
                          formattedData.filter((i) => i.key === item)[0]
                        )
                      }
                      onBlur={() => setHoverItem(undefined)}
                      data={formattedData}
                    />
                  </div>
                </div>
              ) : (
                <p className="center-text-error">{ERROR_LIST.NO_DATA}</p>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}

export default Co2Emission;
