import React, { useEffect, useState, useContext, useCallback, lazy, Suspense } from "react";
import { TabItem, TabControl } from "@abb/abb-common-ux-react";
import "./charts-tab.css";
import { RootContext } from "../../contexts/root-context";
import { EMCORE_ERROR_LIST } from "../../strings";
import { fetchInputFileData } from "../../components/Microgrid-Opt-Components/api";
import Load_Cmp_6 from "../../components/Microgrid-Opt-Components/Dashboard-cmpts/Load-profiles/Load_Cmp_6";
import Load_Cmp_7 from "../../components/Microgrid-Opt-Components/Dashboard-cmpts/Load-profiles/Load_Cmp_7";
import Load_Cmp_8 from "../../components/Microgrid-Opt-Components/Dashboard-cmpts/Load-profiles/Load_Cmp_8";
import Battery_SoC_1 from "../../components/Microgrid-Opt-Components/Dashboard-cmpts/BatterySoC/battery-soc-1";


// Lazy load components
const Load_Cmp = lazy(() => import("../../components/Microgrid-Opt-Components/Dashboard-cmpts/Load-profiles/load-profiles"));
const Load_Cmp_2 = lazy(() => import("../../components/Microgrid-Opt-Components/Dashboard-cmpts/Load-profiles/load-profiles-2"));
 const Load_Cmp_3 = lazy(() => import("../../components/Microgrid-Opt-Components/Dashboard-cmpts/Load-profiles/load-profilles-3"));
 const Load_Cmp_4 = lazy(() =>import  ("../../components/Microgrid-Opt-Components/Dashboard-cmpts/Load-profiles/load-profilles-4"));
 const Load_Cmp_5 = lazy(() =>import  ("../../components/Microgrid-Opt-Components/Dashboard-cmpts/Load-profiles/load-profilles-5"));
//  const Load_Cmp_6 = lazy(() =>import  ("../../components/Microgrid-Opt-Components/Dashboard-cmpts/Load-profiles/Load_Cmp_6"));
//  const Load_Cmp_7 = lazy(() =>import  ("../../components/Microgrid-Opt-Components/Dashboard-cmpts/Load-profiles/load-profilles-7"));
//  const Load_Cmp_8 = lazy(() =>import  ("../../components/Microgrid-Opt-Components/Dashboard-cmpts/Load-profiles/load-profilles-8"));


const NetworkDiagram = lazy(() => import("../../components/Microgrid-Opt-Components/Network/network"));
const Battery_SoC = lazy(() => import("../../components/Microgrid-Opt-Components/Dashboard-cmpts/BatterySoC/battery-soc"));
const NetworkGreenEnergy = lazy(() => import("../../components/Microgrid-Opt-Components/Dashboard-cmpts/Generation/generation"));
const NetworkNonGreenEnergy = lazy(() => import("../../components/Microgrid-Opt-Components/Dashboard-cmpts/Generation/non-green-energy"));
const PriceForecast = lazy(() => import("../../components/Microgrid-Opt-Components/Dashboard-cmpts/Grid/price-forecast"));
const TotalGridPower = lazy(() => import("../../components/Microgrid-Opt-Components/Dashboard-cmpts/Grid/grid-power"));
const MGCard = lazy(() => import("../../components/Microgrid-Opt-Components/MGCards/mgcard"));

function MicrogridChartsTab() {
    const { isInputSettingClicked, setstartTS, setendTS, endTS, startTS } = useContext(RootContext);
    const isFileUploaded = JSON.parse(localStorage.getItem('isFileUploaded'));
    const [activeTab, setActiveTab] = useState(0);
    const [type, setType] = useState('primary');
    const [monochrome, setMonochrome] = useState(false);
    const [selectedLoad, setselectedLoad] = useState("");
    const [fetchLoads, setfetchLoads] = useState([]);
    const [fetchBatteries, setfetchBatteries] = useState([]);

    const getAllLoadComponents = useCallback(async () => {
        const response = await fetchInputFileData("https://emcore-data-bucket-test.s3.amazonaws.com/input/network.json", {});
        const nodes = response?.nodes;
        const loads = [];
        const batteries=[];
        nodes?.forEach((node) => {
            const loadPattern = /^load\d{1,2}[a]?$/;
            const batteryPattern = /^battery\d{1,2}[a]?$/;
            if (loadPattern.test(node.network_node_label)) {
                loads.push(node.network_node_label + " - " + node.node_tag);
            }
            if (batteryPattern.test(node.network_node_label)) {
              batteries.push(node.network_node_label + " - " + node.node_tag);
          }
        });
        setfetchLoads(loads);
        setfetchBatteries(batteries);
    }, []);

    useEffect(() => {
        getAllLoadComponents();
    }, [getAllLoadComponents]);

    const initialTabs = () => {
        return [
            <TabItem key="1" title="Network">
                {isFileUploaded ? (
                    <Suspense fallback={<div>Loading Network Diagram...</div>}>
                        <NetworkDiagram />
                    </Suspense>
                ) : (
                    <span style={{ margin: "auto" }} color="dark-grey" size="small">
                        {EMCORE_ERROR_LIST.ERROR_FILE_NOT_UPLOADED}
                    </span>
                )}
            </TabItem>,
           <TabItem key="2" title="Load Profiles">
           {(isFileUploaded && activeTab === 1) ? (
             <div className="row" id="load-profiles-tab-content">
               
                 <Suspense fallback={<div>Loading Load Profiles...</div>}>
                   <MGCard
                     widgetName={fetchLoads[0]}
                     component={<Load_Cmp/>} 
                     applyScroll="true"
                   />
                    <MGCard
                     widgetName={fetchLoads[1]}
                     component={<Load_Cmp_2/>} 
                     applyScroll="true"
                   />
                     <MGCard
                     widgetName={fetchLoads[2]}
                     component={<Load_Cmp_3/>} 
                     applyScroll="true"
                   />
                      <MGCard
                     widgetName={fetchLoads[3]}
                     component={<Load_Cmp_4/>} 
                     applyScroll="true"
                   />

                    <MGCard
                     widgetName={fetchLoads[4]}
                     component={<Load_Cmp_5/>} 
                     applyScroll="true"
                   />
                    <MGCard
                     widgetName={fetchLoads[5]}
                     component={<Load_Cmp_6/>} 
                     applyScroll="true"
                   />
                     <MGCard
                     widgetName={fetchLoads[6]}
                     component={<Load_Cmp_7/>} 
                     applyScroll="true"
                   />
                      <MGCard
                     widgetName={fetchLoads[7]}
                     component={<Load_Cmp_8/>} 
                     applyScroll="true"
                   />
                 </Suspense>
               
             </div>
           ) : (
             <span style={{ margin: "auto" }} color="dark-grey" size="small">
               {EMCORE_ERROR_LIST.ERROR_FILE_NOT_UPLOADED}
             </span>
           )}
         </TabItem>,
            <TabItem key="3" title="Battery SoC">
                {isFileUploaded &&  activeTab === 2? (
                    <div className="row" id="load-profiles-tab-content">
                        <Suspense fallback={<div>Loading Battery SoC...</div>}>
                            <MGCard widgetName={fetchBatteries[0]} component={<Battery_SoC />} applyScroll="true" />
                            <MGCard widgetName={fetchBatteries[1]} component={<Battery_SoC_1 />} applyScroll="true" />
                        </Suspense>
                    </div>
                ) : (
                    <span style={{ margin: "auto" }} color="dark-grey" size="small">
                        {EMCORE_ERROR_LIST.ERROR_FILE_NOT_UPLOADED}
                    </span>
                )}
            </TabItem>,
            <TabItem key="4" title="Generation">
                {isFileUploaded ? (
                    <div className="row" id="load-profiles-tab-content">
                        <Suspense fallback={<div>Loading Generation...</div>}>
                            <MGCard widgetName="Green Energy" component={<NetworkGreenEnergy />} applyScroll="true" />
                            <MGCard widgetName="Non Green Energy" component={<NetworkNonGreenEnergy />} applyScroll="true" />
                        </Suspense>
                    </div>
                ) : (
                    <span style={{ margin: "auto" }} color="dark-grey" size="small">
                        {EMCORE_ERROR_LIST.ERROR_FILE_NOT_UPLOADED}
                    </span>
                )}
            </TabItem>,
            <TabItem key="5" title="Grid">
                {isFileUploaded ? (
                    <div className="row" id="load-profiles-tab-content">
                        <Suspense fallback={<div>Loading Grid Components...</div>}>
                            <MGCard widgetName="Green Energy Price Forecast" component={<PriceForecast />} applyScroll="true" />
                            <MGCard widgetName="Total Grid Power" component={<TotalGridPower />} applyScroll="true" />
                        </Suspense>
                    </div>
                ) : (
                    <span style={{ margin: "auto" }} color="dark-grey" size="small">
                        {EMCORE_ERROR_LIST.ERROR_FILE_NOT_UPLOADED}
                    </span>
                )}
            </TabItem>
        ];
    };
    const [tabs, setTabs] = useState(initialTabs);
    useEffect(()=>{
     
    },[startTS,endTS,tabs])
    return (
        <>
            <TabControl
                type={type}
                id="mg-dashboard-tabs-container"
                style={{ paddingTop: "16px" }}
                activeTab={activeTab}
                onTabChange={(oldIndex, newIndex) => setActiveTab(newIndex)}
                monochrome={monochrome}
            >
                {initialTabs()}
            </TabControl>
        </>
    );
}

export default MicrogridChartsTab;
