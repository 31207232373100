import React, { useEffect } from "react";
import { Auth } from "aws-amplify";
import { useNavigate, useLocation } from "react-router-dom";

const UserSession = () => {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    
    const checkUserSession = async () => {
      try {
        const currentSession = await Auth.currentSession().then((session) => {

          if (location.pathname === "/") {
            navigate("./dashboard");
          } else if (
            location.pathname === "/dashboard" ||
            location.pathname === "/dashboard/"
          ) {
            
            navigate("./");
          }
          //  Check if the access token is expired
          const accessToken = session.getAccessToken();

          const expirationTime = new Date(accessToken.payload.exp * 1000);

          const currentTime = new Date();

          if (expirationTime < currentTime) {
            console.log("Access token expired, refreshing session...");

            // Refresh the session using the refresh token
            const refreshedSession = Auth.refreshSession(
              currentSession.refreshToken
            );

            
          }
        });
      } catch (error) {
        console.log(error);
        if (error == "No current user") {
          console.log(error);
          navigate("/");
        }
      }
    };
    checkUserSession();
  }, []);

  return <></>;
};

export default UserSession;
