import React, { useCallback, useContext, useEffect, useState } from 'react';
import './network.css';
import { Link, Icon } from '@abb/abb-common-ux-react';
import { EMCORE_DASHBOARD_BASE_API_URL } from '../../../strings';
function NetworkPreview() {
    const networkImg=`${EMCORE_DASHBOARD_BASE_API_URL}/network_visualization.png`;

    return (

        <img
      src={networkImg}
      style={{
        width: "100%",
        height: "800px",
        border: "none",
      }}
      title="Network Visualization"
    />
    )
}
export default NetworkPreview;
