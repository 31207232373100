import React, { useEffect, useRef } from 'react';
import * as echarts from 'echarts';
import { Dropdown, DropdownOption } from '@abb/abb-common-ux-react';
import "./enpi-monitoring.css";
import PeriodList from '../Period-Dropdown-list/period-dropdown-list';
const ENPI_MONITORING = () => {
    const chartRef = useRef(null);
    const [monochrome, setMonochrome] = React.useState(false);

    useEffect(() => {
        const data = [
            { name: 'Jan', value: 120 },
            { name: 'Feb', value: 150 },
            { name: 'Mar', value: 180 },
            { name: 'Apr', value: 200 },
            { name: 'May', value: 220 },
            { name: 'Jun', value: 250 },
        ];

        const chart = echarts.init(chartRef.current);

        const option = {
            tooltip: {
                trigger: 'axis'
            },
            xAxis: {
                type: 'category',
                data: data.map(item => item.name),
                name: 'Date',
                axisLabel: {

                    padding: -2 // Add padding/margin between y-axis values and title
                },
                nameLocation: "middle",
                axisTick: {
                    alignWithLabel: true
                }
            },
            // legend: {
            //     data: ['Active Power'] // Add legend with title "Active Power"
            // },
            // series: [{
            //     name: 'Active Power', // Legend item name
            //     type: 'line',
            //     data: data.map(item => item.value)
            // }],
            yAxis: {
                type: 'value',
                // axisLabel: {
                //     rotate: 45 // Rotate y-axis label by 45 degrees
                // },
                axisLabel: {

                    padding: -15, // Add padding/margin between y-axis values and title
                    verticalAlign: 'top', // Aligns the labels towards the top
                    lineHeight: 10, // Adjust the line height as needed
                },
                name: 'Total Active Power [kW]', // Title for y-axis
                nameLocation: 'center', // Set y-axis title location to start (left side)
                nameRotate: 90// Do not rotate y-axis title (name)

            },
            series: [{
                type: 'line',
                data: data.map(item => item.value),
                itemStyle: {
                    color: '#E12E2E' // Set bar color to #FF4754
                }
            }]
        };

        chart.setOption(option);

        return () => {
            chart.dispose();
        };
    }, []);

    return (

        <div className='enpi-block'>
            <div className='indicator-container-line'>
                <Dropdown
                    label="Group"
                    placeholder="ABB SACE BUILDING - PLANT"
                    monochrome={monochrome}
                >
                    <DropdownOption key="Presenze site" label="ABB SACE BUILDING - PLANT" value="ABB SACE BUILDING - PLANT" />
                </Dropdown>
                <Dropdown

                    label="Compare Group"
                    placeholder=""
                    monochrome={monochrome}
                >
                    <DropdownOption key="Energy" label="Energy" value="Energy" />
                </Dropdown>
                <PeriodList />
            </div>
            <div ref={chartRef} style={{ width: '545px', height: '280px' }} />
        </div>
    )
};


export default ENPI_MONITORING;
