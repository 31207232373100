import React, { useEffect, useRef } from 'react';
import * as echarts from 'echarts';
import "./power-generation.css"
const GaugePowerGenerator = () => {
    const chartRef = useRef(null);
    const myChart = useRef(null);

    useEffect(() => {
        myChart.current = echarts.init(chartRef.current);

        const option = {
            series: [
                {
                    type: 'gauge',
                    center: ['50%', '60%'],
                    startAngle: 200,
                    endAngle: -20,
                    min: 0,
                    max: 100,
                    progress: {
                        show: true,
                        width: 10,
                        itemStyle: {
                            color: '#51A34B', // Color for the data range
                        },
                        backgroundStyle: {
                            color: '#DBDBDB' // Color for the data track (background)
                        }
                    },
                    detail: {
                        formatter: '{value}%\nPower Generation',
                        offsetCenter: [0, 0],// Adjust offsetCenter to center the value text

                        textStyle: { // Add textStyle to modify font size
                            fontSize: 18, // Adjust the font size here

                        }
                    },
                    axisLine: {
                        lineStyle: {
                            width: 10, // Set axis line width to 0 to hide it
                        }
                    },
                    axisLabel: {
                        show: false, // Hide axis labels
                    },
                    axisTick: {
                        show: false, // Hide axis ticks
                    },
                    splitLine: {
                        show: false, // Hide split lines
                    },
                    pointer: {
                        show: false, // Hide pointer
                    },
                    data: [
                        { value: 28 }
                    ],
                }
            ]
        };

        myChart.current.setOption(option);

        // const updateDataInterval = setInterval(() => {
        //     const random = +(Math.random() * 60).toFixed(2);
        //     myChart.current.setOption({
        //         series: [
        //             {
        //                 data: [
        //                     {
        //                         value: random
        //                     }
        //                 ]
        //             },
        //             {
        //                 data: [
        //                     {
        //                         value: random
        //                     }
        //                 ]
        //             }
        //         ]
        //     });
        // }, 2000);

        // return () => clearInterval(updateDataInterval);
    }, []);

    return (
        <div className='power-container'>
            <div className='chart-holder'>
                <div className='chart-container' ref={chartRef}></div>
            </div>
            <div className='power-info'>
                <div className='power-inner'>
                    <div className='power-metrics'>
                        <span>Generated power</span>
                        <span style={{ "fontSize": "16px", "fontWeight": "bold" }}>52,0 kW</span>
                    </div>
                    <div className='power-metrics'>
                        <span>Site generated capacity</span>
                        <span style={{ "fontSize": "16px", "fontWeight": "bold" }}>180,0 kW</span>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default GaugePowerGenerator;
