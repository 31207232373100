import React, { useContext, useEffect, useState } from "react";
import { RootContext } from "../../contexts/root-context";
import NetZeroCard from "../../components/Net-Zero-Components/Net-Zero-Card/net-zero-card";
import { TRENDS_CARD_JSON, NZ_BASE_URL } from "../../strings";
import { Spinner } from "@abb/abb-common-ux-react";
import "./trends.css";
import axios from "axios";

function Trends() {
  const { setPowerTrendsData, setForecastData } = useContext(RootContext);
  const [isSpin, setIsSpin] = useState(true);

  const fetchPowerTrendsData = async () => {
    setIsSpin(true);
    try {
      const response = await axios.get(`${NZ_BASE_URL}/power_trends`);
      setPowerTrendsData(response.data);
      setIsSpin(false);
    } catch (error) {
      console.error("Error fetching power trends:", error);
      setIsSpin(true);
    }
  };

  useEffect(() => {
    fetchPowerTrendsData(); // Initial fetch
    const intervalId = setInterval(fetchPowerTrendsData, 900000);

    return () => clearInterval(intervalId);
  }, [setPowerTrendsData]);

  const fetchForecastData = async () => {
    try {
      const response = await axios.get(`${NZ_BASE_URL}/forecast`);
      setForecastData(response.data);
      setIsSpin(false);
    } catch (error) {
      console.error("Error fetching forecast:", error);
      setIsSpin(true);
    }
  };
  useEffect(() => {
    fetchForecastData(); // Initial fetch
    const intervalId = setInterval(fetchForecastData, 900000);

    return () => clearInterval(intervalId);
  }, [setForecastData]);

  return (
    <>
      {isSpin ? (
        <Spinner style={{ margin: "auto" }} color="dark-grey" size="small" />
      ) : (
        <>
          <div className="trends-container">
            {TRENDS_CARD_JSON?.map((widget, index) => (
              <NetZeroCard
                key={index}
                widgetName={widget.widgetName}
                widgetType={widget.widgetType}
                nzcomponent={widget.nzcomponent}
                dropdownComponent={widget.dropdownComponent}
              />
            ))}
          </div>
        </>
      )}
    </>
  );
}

export default Trends;
