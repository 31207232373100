import React, { useContext, useState } from "react";
import { DropdownOption, Dropdown, Icon } from "@abb/abb-common-ux-react";
import NzDropdownDeviceMeasureLegandName from "./nz-dropdown-device-measure-legendName";
import { RootContext } from "../../../contexts/root-context";

function NzDropdownDeviceMeasures() {
  const { setSelectedDeviceNameOption, setSelectedDeviceMeasureOption } =
    useContext(RootContext);
  const deviceNameList = [
    "HVAC_2N",
    "LOADS_NORD",
    "HVAC_GN",
    "HVAC_2S",
    "MAIN_QG1",
    "MAIN_QG2",
    "HVAC_1N",
    "HVAC_GS",
    "SOLAR_ROOF",
    "HVAC_3N",
    "MAIN_QG3",
    "HVAC_3S",
    "MAIN_QG4",
    "HVAC_1S",
    "EV1",
  ];

  const measureList = [
    {
      name: "f",
      unit: "Hz",
      legendName: "Frequency",
    },
    {
      name: "iL1",
      unit: "A",
      legendName: "Current Phase A",
    },
    {
      name: "iL2",
      unit: "A",
      legendName: "Current Phase B",
    },
    {
      name: "iL2",
      unit: "A",
      legendName: "Current Phase C",
    },
    {
      name: "u1",
      unit: "V",
      legendName: "Voltage phase A - neutral",
    },
    {
      name: "u2",
      unit: "V",
      legendName: "Voltage phase B - neutral",
    },
    {
      name: "u3",
      unit: "V",
      legendName: "Voltage phase C - neutral",
    },
    {
      name: "u12",
      unit: "V",
      legendName: "Voltage phase A - phase B",
    },
    {
      name: "u23",
      unit: "V",
      legendName: "Voltage phase B - phase C",
    },
    {
      name: "u31",
      unit: "V",
      legendName: "Voltage phase C - phase A",
    },
    {
      name: "p1",
      unit: "kW",
      legendName: "Active power phase A",
    },
    {
      name: "p2",
      unit: "kW",
      legendName: "Active power phase B",
    },
    {
      name: "p3",
      unit: "kW",
      legendName: "Active power phase C",
    },
    {
      name: "q1",
      unit: "kVAR",
      legendName: "Reactive power phase A",
    },
    {
      name: "q2",
      unit: "kVAR",
      legendName: "Reactive power phase B",
    },
    {
      name: "q3",
      unit: "kVAR",
      legendName: "Rective power phase C",
    },
    {
      name: "s1",
      unit: "kVAR",
      legendName: "Apparent power phase A",
    },
    {
      name: "s2",
      unit: "kVAR",
      legendName: "Apparent power phase B",
    },
    {
      name: "s3",
      unit: "kVAR",
      legendName: "Apparent power phase C",
    },
    {
      name: "nOperations",
      unit: "count",
      legendName: "Number of operations",
    },
    {
      name: "nTrips",
      unit: "count",
      legendName: "Number of trips",
    },
  ];
  const [options] = useState(
    deviceNameList.map((label, i) => ({
      label: label,
      value: i,
    }))
  );

  const [disabled, setDisabled] = React.useState(false);
  const [useCustomTemplates, setUseCustomTemplates] = React.useState(false);
  const [monochrome, setMonochrome] = React.useState(false);
  const [initialOpen, setInitialOpen] = React.useState("none");
  const [selectedMulti, setSelectedMulti] = useState([options[0]]);

  const commonFlags = {
    allowAdd: false,
    clearable: true,
    searchable: true,
    clearOnEscape: true,
    sizeClass: "small",
  };

  const getMenuItemTemplate = (o) => (
    <span
      style={{
        flex: 1,
        padding: "16px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        <span>{o.label}</span>
      </div>
    </span>
  );

  const getSelectedItemTemplate = (o) => (
    <span
      style={{
        padding: "16px",
        display: "flex",
        alignItems: "center",
      }}
    >
      {o.label}
    </span>
  );

  const handleDropdownChangeMulti = (selectedValues) => {
    setSelectedMulti(selectedValues);
    setSelectedDeviceNameOption(selectedValues.map((obj) => obj.label));
  };

  return (
    <>
      <Dropdown
        placeholder="Select a Device"
        monochrome={monochrome}
        multiselect={true}
        value={selectedMulti}
        onChange={handleDropdownChangeMulti}
        initialOpen={initialOpen === "multi"}
        {...commonFlags}
      >
        {options.map((o, i) => (
          <DropdownOption
            key={i}
            label={o.label}
            value={o.value}
            disabled={o.disabled}
            menuItemTemplate={getMenuItemTemplate(o)}
            selectedItemTemplate={getSelectedItemTemplate(o)}
          />
        ))}
      </Dropdown>
      {/* <Dropdown
        placeholder="Select a Measure"
        monochrome={monochrome}
        multiselect={true}
        value={selectedMulti}
        onChange={(val) => setSelectedDeviceMeasureOption(val)}
        initialOpen={initialOpen === "multi"}
        {...commonFlags}
      >
        {measureList.map((o, i) => (
          <DropdownOption
            key={o.legendName}
            label={o.legendName}
            value={o.legendName}
            disabled={o.disabled}
            menuItemTemplate={getMenuItemTemplate(o.legendName)}
            selectedItemTemplate={getSelectedItemTemplate(o.legendName)}
          />
        ))}
      </Dropdown> */}
      <NzDropdownDeviceMeasureLegandName />
    </>
  );
}
export default NzDropdownDeviceMeasures;
