import React from "react";
import Navbar from "../../components/Navbar/navbar";
import NetZeroNavBar from "../../components/Net-Zero-Components/Net-Zero-Navbar/net-zero-navbar";

function NetZeroDashboard() {
  return (
    <>
      <Navbar />
      <NetZeroNavBar />
    </>
  );
}

export default NetZeroDashboard;
