import React, { useContext } from "react";
import { Gauge } from "@abb/abb-common-ux-react";
import "./kpi-gauge.css";
import { RootContext } from "../../../contexts/root-context";
import { useEffect } from "react";

function GreenEnergy() {
  const { siteKpis } = useContext(RootContext);
  const valueForSolarTarget = siteKpis?.siteKPIMetrics?.greenEnergy?.solar;
  const [value, setValue] = React.useState(valueForSolarTarget || 180);
  const [range, setRange] = React.useState([0, 200]);
  const [decimals, setDecimals] = React.useState(0);
  const [warnings, setWarnings] = React.useState([20, 100]);
  const [alarms, setAlarms] = React.useState([5, 150]);
  const [limitMarkerStyle, setLimitMarkerStyle] = React.useState("none");
  // Update value state when valueForSolarTarget changes
  useEffect(() => {
    if (valueForSolarTarget !== undefined) {
      setValue(valueForSolarTarget);
    }
  }, [valueForSolarTarget]);
  return (
    <div
      className="kpi-gauge-content"
      style={{
        // marginTop: "30px",
        // height: "300px",
        width: "100%",
      }}
    >
      <Gauge
        className="nz-gauge-style"
        type="radial"
        min={range[0]}
        max={range[1]}
        minLabel={" "}
        maxLabel={" "}
        lowAlarm={alarms[0]}
        highAlarm={alarms[1]}
        lowWarn={warnings[0]}
        highWarn={warnings[1]}
        unit="kWh"
        value={value}
        limitStyle={limitMarkerStyle}
        decimals={decimals}
      />
    </div>
  );
}

export default GreenEnergy;
