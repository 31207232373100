import React, { useContext } from "react";
import "./consumption-by-occupancy.css";
import { RootContext } from "../../../contexts/root-context";

function ConsumptionByOccupancy() {
  const { siteKpis } = useContext(RootContext);
  const occupancyValue =
    siteKpis?.siteKPIMetrics?.consumptionByOccupancy?.occupancyPercentage +
      "%" || "40%";
  const consmuptionByOccupancyValue =
    siteKpis?.siteKPIMetrics?.consumptionByOccupancy?.value || 2.5;
  const consumptionByOccupancyKpi =
    siteKpis?.siteKPIMetrics?.consumptionByOccupancy?.kwh || 1.9;

  return (
    <div className="consumption-by-occupancy-container">
      <span>Occupancy {occupancyValue}</span>
      <div className="divider"></div>
      <div className="number">{consmuptionByOccupancyValue}</div>
      <div className="divider"></div>
      <span>kWh/YTD {consumptionByOccupancyKpi}</span>
    </div>
  );
}

export default ConsumptionByOccupancy;
