import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Dialog } from "@abb/abb-common-ux-react";
import { RootContext } from "../../../contexts/root-context";
import { Button } from '@abb/abb-common-ux-react';
import './proofer-form.css'; // Add your custom styles here
import { useContext } from 'react';
import { Spinner } from '@abb/abb-common-ux-react';
import S3 from 'react-aws-s3';
import AWS from 'aws-sdk';

window.Buffer = window.Buffer || require("buffer").Buffer;

const ProoferForm = (props) => {


    // Configure AWS to use your Cognito Identity Pool ID

    const { showDialog, setShowDialog } = props;
    const [programmaticDialogIsOpen, setProgrammaticDialogIsOpen] = React.useState(true);
    const { currentProoferState, setcurrentProoferState } = useContext(RootContext);
    // const postRequestUrlPrefix = "http://ec2-13-235-65-148.ap-south-1.compute.amazonaws.com:8002/";
    const postRequestUrlPrefix = "https://api.dev.demo.cognien.abb/proofer/";
    const [processDocResponse, setProcessDocResponse] = useState({});
    const [formData, setFormData] = useState({
        opportunityNumber: '',
        projectName: '',
        customerName: '',
        productGroup: '',
        file: null,
        techRequirementFrom: '',
        techRequirementTo: '',
        commRequirementFrom: '',
        commRequirementTo: ''
    });

    let accesskey = "ASIAYCEI3CCPRKBG64SL";
    let secretkey = "+rNbtdHc2MQ8TgEqoFGEBwJj3KHM0jljXpPCR25Q";

    const navigate = useNavigate();
    const config = {
        bucketName: 'proofer-hackathon-demo-bucket',
        region: "ap-south-1",
        accessKeyId: accesskey,
        secretAccessKey: secretkey,
        s3Url: 'https://proofer-hackathon-demo-bucket.s3.ap-south-1.amazonaws.com/', // Optional

    };

    const [selectedFile, setSelectedFile] = useState(null);
    const [uploadSuccess, setUploadSuccess] = useState(false);
    const [uploading, setUploading] = useState(false);
    // const [selectedFileKey, setSelectedFileKey] = useState("");
    const [loading, setLoading] = useState(false);

    // AWS S3 configuration
    const S3_BUCKET = 'proofer-hackathon-demo-bucket';
    const REGION = 'ap-south-1';

    AWS.config.update({
        accessKeyId: 'ASIAYCEI3CCPW32MQYXR',
        secretAccessKey: 'YZ0H4VZm/v4sYW/i7a9PbgV0GQXQL3sTZ8c0genM',
        sessionToken: 'IQoJb3JpZ2luX2VjEJf//////////wEaCXVzLWVhc3QtMSJGMEQCIH5K5eeHU4xHEDU0j47Hobkn/cCI6tsciz+N1ar7TEcIAiBam1GVvS+ipkoTB0hH8Th/WJ87wFrbRPw6uiCT6FdiViruAgjQ//////////8BEAEaDDU1NDMzNzc2NzU4MyIMUCgBQKfsqZuJvzGjKsICKiOA2H+ZWjGO7XfRxrRI0Cv7hG9AolCbnNkyZ5a+ExRu4Q483R2HgZDOiRtoV82RIaXl7y6BmDaMfwLJp6TFoR2WhJhV78AM86SHlZ3IMm1EmntGy+j2FerUo4PCSXOZoicvZCSOUI9m6hEdsLtfLwuv/7PpDPHTk78u8uez+hIhIFfg9TsiGGJlgF2aVAnXuEhQDad3WfPIT60KWyozEbn1NgU5OE+K1YCFIwLi/NLmtgedd9lxeZTN5KYFv2l755+ssMDkUdvl5oFMcxvJ0PvxPUtanAx2S8a8qOpZV+FKLCLZacVoX4aMfzPdNCtvp+buwG3FNl/WZMLYshmIQRKaPUPHGcC2b1LPZXj9V1tv+Do/nQK4FATccPxG5SOFUazr4Gahayb8Pyoo8LZMJ1Pxh2mNFLWfW6k5k6FsE86RejDcvMm3BjqoAZpCq+tTYCoTOUvwquXn+iy51jiZ/SUP94NlwF9RGDU7b/x5+u3yIg4/4F4pgzVfSrCSA/tVPbx6ts0tyV3CXWCeyln/y0lUf6qsvS/FVxnsyFOQMvB1NKVT63cmXKwQ1XyzavGRlV5K13IQgj2e1huMPMFEbo5iJXG1hRHapB/aJ1q7DivC7cVLCA9hOoh/RyRe2LYp0VVGgpsj8+CUPKlFVgnbf/hGUg==',
        region: REGION,
    });

    const myBucket = new AWS.S3({
        params: { Bucket: S3_BUCKET },
        region: REGION,
    });

    const handleFileInput = (e) => {
        setSelectedFile(e.target.files[0]);
    };
    useEffect(()=>{

    },[showDialog])
    const uploadFile = (file) => {
        if (!file) {
            alert("Please select a file to upload.");
            return;
        }
        const selectedFileKey = 'documents/input/' + selectedFile.name;
        // TODO: fix this properly
        const params = {
            ACL: 'public-read',
            Body: file,
            Bucket: S3_BUCKET,
            Key: selectedFileKey,
            ContentType: file.type,
        };

        setUploading(true);

        myBucket.putObject(params)
            .on('httpUploadProgress', (evt) => {
                console.log(`Progress: ${Math.round((evt.loaded / evt.total) * 100)}%`);
            })
            .send((err) => {
                if (err) {
                    console.log("Upload Error", err);
                    setUploadSuccess(false);
                } else {
                    console.log("Upload Success");
                    setUploadSuccess(true);
                }
                setUploading(false);
            });
    };
    // const [loading, setLoading] = useState(false);
    // const [selectedFile, setSelectedFile] = useState(null);



    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]);

        setFormData({ ...formData, file: e.target.files[0] });
    };

    // const uploadFile = async () => {
    //     console.log('Uploading file', selectedFile);
    //     if (!selectedFile) {
    //         alert('Please select a file first!');
    //         return;
    //     }

    //     const s3Client = new S3(config);
    //     s3Client.uploadFile(selectedFile).then(async (data) => {
    //         console.log(data);
    //         console.log(data.key);
    //     })
    //     // try {
    //     //     const response = await s3Client.uploadFile(selectedFile);
    //     //     console.log("upload");
    //     //     console.log(response);
    //     //     alert('File uploaded successfully!');
    //     // } catch (error) {
    //     //     console.error('Error uploading file:', error.message);
    //     //     alert('Error uploading file!');
    //     // }
    // };

    const mockResponse = {
        state: 'Submitted',
        createdOn: '2024-09-16',
        id: '12345'
    };
    // const showProoferTabs = () => {
    //     setcurrentProoferState("showtabs")

    // };

    const handleSubmit = () => {
        setLoading(true);
        const data = new FormData();
        data.append('opportunityNumber', formData.opportunityNumber);
        data.append('projectName', formData.projectName);
        data.append('customerName', formData.customerName);
        data.append('productGroup', formData.productGroup);
        data.append('file', formData.file);
        data.append('techRequirementFrom', formData.techRequirementFrom);
        data.append('techRequirementTo', formData.techRequirementTo);
        data.append('commRequirementFrom', formData.commRequirementFrom);
        data.append('commRequirementTo', formData.commRequirementTo);
        console.log("formData data", formData);

        // console.log("data: ", data, formData.opportunityNumber, data.get('opportunityNumber'));
        const mockData = {
            "name": selectedFile.name,
            "path": "/store/documents/proofer/Enquiry Document RFQ 1.pdf",
            "path_type": "local",
            "type": "pdf",
            "metadata": {
                "author": "John Doe",
                "date": "2023-09-01"
            },
            "page_range_array": [
                [102, 117]
            ],
            "reset_db": true
        }

        const headers = {
            "Content-Type": "application/json",
        };

        axios.post(postRequestUrlPrefix + 'process_pdf', mockData,{ headers })
            .then((response) => {
                // setProcessDocResponse(response.data);
                // console.log("response: ", response.data);
                setShowDialog(false);
                console.log("response form", response);
                setcurrentProoferState("showtabs");
                localStorage.setItem('prooferFormResponse', JSON.stringify(response.data));
                localStorage.setItem('prooferFormData', JSON.stringify(formData));
                navigate('./popup-output-tabs', { state: { formData, response: response.data } });
                setLoading(false);
                
            })
            .catch((error) => {
                console.log(error)
                setLoading(false);
            });
    };

    return (

        <div>

            <Dialog
                name="proofer-form"
                isOpen={showDialog}
                sizClass={"large"}
                id="popup-header"
                onClose={() => {
                    console.log('onClose');
                    setShowDialog(false);
                    setProgrammaticDialogIsOpen(false);
                }}
                className='ReactModal__Content ReactModal__Content--after-open ABB_CommonUX_Dialog__dialog ABB_CommonUX_Dialog__dark'
                style={{ "z-index": "3 !important" }}
                showCloseButton={true}
                closeOnEscape={true}
                closeOnLostFocus={true}

                onOpen={() => console.log('onOpen')}
                title={'Proofer form'}

                render={(dlg) => (
                    <form id="proofer-form-container">
                        <div>    {loading ? <Spinner
                            style={{ marginLeft: "40%" }}
                            color="red"

                            sizeClass={"medium"}
                        ></Spinner> : ""}</div>
                        <label>Opportunity Number</label>
                        <input
                            type="text"
                            name="opportunityNumber"
                            placeholder="OPP.__._____"
                            value={formData.opportunityNumber}
                            onChange={handleInputChange}
                        />
                        <label>Project Name</label>
                        <input
                            type="text"
                            name="projectName"
                            value={formData.projectName}
                            onChange={handleInputChange}
                        />
                        <label>Customer Name</label>
                        <input
                            type="text"
                            name="customerName"
                            value={formData.customerName}
                            onChange={handleInputChange}
                        />
                        <label>Product Group</label>
                        <select
                            name="productGroup"
                            value={formData.productGroup}
                            className="product-group-select"

                            onChange={handleInputChange}
                        >
                            <option value="">Select Product Group</option>
                            <option value="AIS">AIS</option>
                            <option value="GIS">GIS</option>
                            <option value="Outdoor CB">Outdoor CB</option>
                            <option value="RMU">RMU</option>
                        </select>

                        <label>Upload File</label>

                        <span>
                            <input type="file" onChange={handleFileInput} accept="application/pdf" />
                            <Button


                                onClick={() => uploadFile(selectedFile)} disabled={uploading}
                                text={uploading ? 'Uploading...' : 'Upload PDF'}
                                shape="rounded"
                                sizeClass='small'
                                type="primary-red"
                            /></span>
                        <div className="tech-requirements">
                            <label>Technical &nbsp;&nbsp; Requirement</label>
                            <input
                                type="text"
                                name="techRequirementFrom"
                                className='page-range'
                                placeholder="From"
                                value={formData.techRequirementFrom}
                                onChange={handleInputChange}
                            />
                            <input
                                type="text"
                                name="techRequirementTo"
                                placeholder="To"
                                className='page-range'
                                value={formData.techRequirementTo}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="comm-requirements">
                            <label>Commercial Requirement</label>
                            <input
                                type="text"
                                name="commRequirementFrom"
                                className='page-range'
                                placeholder="From"
                                value={formData.commRequirementFrom}
                                onChange={handleInputChange}
                            />
                            <input
                                type="text"
                                name="commRequirementTo"
                                className='page-range'
                                placeholder="To"
                                value={formData.commRequirementTo}
                                onChange={handleInputChange}
                            />
                        </div>

                        <div className="buttons" style={{ "margin-top": "5px" }}>
                            <Button
                                text="Create"
                                onClick={() => handleSubmit()}
                                shape="pill"

                                type="primary-red"
                            />
                            <Button
                                text="Cancel"
                                style={{ "margin-left": "15px" }}
                                onClick={() => setShowDialog(false)}
                                shape="pill"
                                type="primary-white"
                            />

                        </div>
                    </form>
                )}
            />
            {/* : ""} */}
        </div>
    );
};

export default ProoferForm;
