import React from "react";
import { TabItem, TabControl } from "@abb/abb-common-ux-react";
import SiteKPIs from "../../../pages/net-zero/site-kpis";
import Home from "../../../pages/net-zero/home";
import Treads from "../../../pages/net-zero/trends";
import DigitalTwins from "../../../pages/net-zero/digital-twin";

function NetZeroNavBar() {
  const [activeTab, setActiveTab] = React.useState(0);

  const handleTabChange = (oldIndex, newIndex) => {
    setActiveTab(newIndex);
  };
  return (
    <div style={{ margin: "1rem 0", width: "100%" }}>
      <TabControl
        type={"secondary"}
        activeTab={activeTab}
        onTabChange={handleTabChange}
      >
        <TabItem key="1" title="Home">
          <Home />
        </TabItem>
        <TabItem key="2" title="Site KPIs">
          <SiteKPIs />
        </TabItem>
        <TabItem key="3" title="Digital Twin">
          <DigitalTwins />
        </TabItem>
        <TabItem key="5" title="Trends">
          <Treads />
        </TabItem>
      </TabControl>
    </div>
  );
}

export default NetZeroNavBar;
