import React, { useEffect, useRef } from 'react';
import * as echarts from 'echarts';
import { Dropdown, DropdownOption } from '@abb/abb-common-ux-react';
import PeriodList from '../Period-Dropdown-list/period-dropdown-list';
import { Checkbox } from '@abb/abb-common-ux-react';
import "./energy-monitoring.css"
const EnergyMonitorBar = () => {
    const chartRef = useRef(null);
    const [monochrome, setMonochrome] = React.useState(false);
    const periodOptions = ["Today", "Yesterday", "Current week", "Current month", "Current semester", "Current year", "Current year"];
    const [chkvalue, setValue1] = React.useState(false);

    useEffect(() => {
        const data = [
            { name: 'Jan', value1: 200, value2: 180, value3: 220 },
            { name: 'Feb', value1: 250, value2: 210, value3: 240 },
            { name: 'Mar', value1: 180, value2: 150, value3: 190 },
            { name: 'Apr', value1: 220, value2: 200, value3: 230 },
            { name: 'May', value1: 210, value2: 190, value3: 220 },
            { name: 'Jun', value1: 280, value2: 250, value3: 270 },
            { name: 'July', value1: 110, value2: 250, value3: 220 },
            { name: 'Aug', value1: 280, value2: 150, value3: 270 },
            { name: 'Sept', value1: 280, value2: 250, value3: 270 },
            { name: 'Oct', value1: 110, value2: 250, value3: 220 },
            { name: 'Nov', value1: 280, value2: 150, value3: 270 },
            { name: 'Dec', value1: 100, value2: 250, value3: 220 },

        ];

        const chart = echarts.init(chartRef.current);
        const option = {
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                }
            },
            xAxis: {
                type: 'category',
                data: data.map(item => item.name),
                axisTick: {
                    alignWithLabel: true
                },
                name: 'Time',
                axisLabel: {
                    padding: -2,

                },
                nameLocation: "middle",
            },
            yAxis: {
                type: 'value',
                axisLabel: {
                    padding: -15,
                    verticalAlign: 'top', // Aligns the labels towards the top
                    lineHeight: 10, // Adjust the line height as needed
                },
                name: 'Energy [kWh]',
                nameLocation: 'center',
                nameRotate: 90
            },
            series: [
                {
                    name: 'Value 1',
                    type: 'bar',
                    data: data.map(item => item.value1),
                    itemStyle: {
                        color: '#FF4754'
                    }
                },
                {
                    name: 'Value 2',
                    type: 'bar',
                    data: data.map(item => item.value2),
                    itemStyle: {
                        color: '#F2AC66'
                    }
                },

            ]
        };

        chart.setOption(option);

        return () => {
            chart.dispose();
        };
    }, []);

    return (
        <div className='energy-monitoring'>
            <div className='indicator-container-line'>
                <Dropdown
                    label="Group"
                    placeholder="ABB SACE BUILDING - PLANT"
                    monochrome={monochrome}
                >
                    <DropdownOption key="Presenze site" label="ABB SACE BUILDING - PLANT" value="ABB SACE BUILDING - PLANT" />
                </Dropdown>
                <Dropdown
                    label="Compare Group"
                    placeholder=""
                    monochrome={monochrome}
                >
                    <DropdownOption key="Energy" label="Energy" value="Energy" />
                </Dropdown>
                <PeriodList />
                <Checkbox style={{ paddingTop: "35px" }} monochrome={monochrome} label={'Compare to Prev Period'} value={chkvalue} onChange={(val) => setValue1(!chkvalue)} />

            </div>
            <div ref={chartRef} className='energy-moni-chart' style={{}} />
        </div>
    );
};

export default EnergyMonitorBar;
