import React, { useContext, useState } from "react";
import { Dropdown, DropdownOption, Icon } from "@abb/abb-common-ux-react";
import { RootContext } from "../../../contexts/root-context";

function NzDropdownPowerFlow() {
  const { setPowerFlowSelectedOption } = useContext(RootContext);
  const powerDeviceNameListWithGroups = [
    "Solar Generated Power",
    "Utility Incoming Power",
    "HVAC Demand Power",
    "EV Chargers Demand Power",
    "HVAC_2N",
    "LOADS_NORD",
    "HVAC_GN",
    "HVAC_2S",
    "MAIN_QG1",
    "MAIN_QG2",
    "HVAC_1N",
    "HVAC_GS",
    "SOLAR_ROOF",
    "HVAC_3N",
    "MAIN_QG3",
    "HVAC_3S",
    "MAIN_QG4",
    "HVAC_1S",
    "EV1",
  ];

  // Initialize options state with powerDeviceNameListWithGroups
  const [options] = useState(
    powerDeviceNameListWithGroups.map((label, i) => ({
      label: label,
      value: i,
    }))
  );

  const [selectedMulti, setSelectedMulti] = useState([options[0]]);

  const commonFlags = {
    allowAdd: false,
    clearable: true,
    searchable: true,
    clearOnEscape: true,
    sizeClass: "small",
  };

  const getMenuItemTemplate = (o) => (
    <span
      style={{
        flex: 1,
        padding: "16px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        <span>{o.label}</span>
      </div>
    </span>
  );

  const getSelectedItemTemplate = (o) => (
    <span
      style={{
        padding: "16px",
        display: "flex",
        alignItems: "center",
      }}
    >
      {o.label}
    </span>
  );

  const handleDropdownChangeMulti = (selectedValues) => {
    setSelectedMulti(selectedValues);
    setPowerFlowSelectedOption(selectedValues.map((obj) => obj.label));
  };

  return (
    <Dropdown
      placeholder="Select a Device"
      multiselect={true}
      value={selectedMulti}
      onChange={handleDropdownChangeMulti}
      {...commonFlags}
    >
      {options.map((o) => (
        <DropdownOption
          key={o.value}
          label={o.label}
          value={o.value}
          disabled={o.disabled}
          menuItemTemplate={getMenuItemTemplate(o)}
          selectedItemTemplate={getSelectedItemTemplate(o)}
        />
      ))}
    </Dropdown>
  );
}

export default NzDropdownPowerFlow;
