import React from "react";
import AbbBuildingImg from "../../../assets/net-zero-images/building2.jpg";
import "./abb-building.css";

function AbbBuilding() {
  return (
    <div className="abb-building">
      <img src={AbbBuildingImg} alt="ABB Building"/>
    </div>
  );
}

export default AbbBuilding;
