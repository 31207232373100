import React, { useContext, useState } from "react";
import { DropdownOption, Dropdown, Icon } from "@abb/abb-common-ux-react";
import { RootContext } from "../../../contexts/root-context";

function NzDropdownDeviceMeasureLegandName() {
  const { selectedDeviceMeasureOption, setSelecteDeviceMeasureOption } =
    useContext(RootContext);
  const measureList = [
    {
      name: "f",
      unit: "Hz",
      legendName: "Frequency",
    },
    {
      name: "iL1",
      unit: "A",
      legendName: "Current Phase A",
    },
    {
      name: "iL2",
      unit: "A",
      legendName: "Current Phase B",
    },
    {
      name: "iL2",
      unit: "A",
      legendName: "Current Phase C",
    },
    {
      name: "u1",
      unit: "V",
      legendName: "Voltage phase A - neutral",
    },
    {
      name: "u2",
      unit: "V",
      legendName: "Voltage phase B - neutral",
    },
    {
      name: "u3",
      unit: "V",
      legendName: "Voltage phase C - neutral",
    },
    {
      name: "u12",
      unit: "V",
      legendName: "Voltage phase A - phase B",
    },
    {
      name: "u23",
      unit: "V",
      legendName: "Voltage phase B - phase C",
    },
    {
      name: "u31",
      unit: "V",
      legendName: "Voltage phase C - phase A",
    },
    {
      name: "p1",
      unit: "kW",
      legendName: "Active power phase A",
    },
    {
      name: "p2",
      unit: "kW",
      legendName: "Active power phase B",
    },
    {
      name: "p3",
      unit: "kW",
      legendName: "Active power phase C",
    },
    {
      name: "q1",
      unit: "kVAR",
      legendName: "Reactive power phase A",
    },
    {
      name: "q2",
      unit: "kVAR",
      legendName: "Reactive power phase B",
    },
    {
      name: "q3",
      unit: "kVAR",
      legendName: "Rective power phase C",
    },
    {
      name: "s1",
      unit: "kVAR",
      legendName: "Apparent power phase A",
    },
    {
      name: "s2",
      unit: "kVAR",
      legendName: "Apparent power phase B",
    },
    {
      name: "s3",
      unit: "kVAR",
      legendName: "Apparent power phase C",
    },
    {
      name: "nOperations",
      unit: "count",
      legendName: "Number of operations",
    },
    {
      name: "nTrips",
      unit: "count",
      legendName: "Number of trips",
    },
  ];

  // Initialize options state with measureList
  const [options] = useState(
    measureList.map((measure, i) => ({
      label: measure.legendName,
      value: i,
    }))
  );

  const [disabled, setDisabled] = useState(false);
  const [useCustomTemplates, setUseCustomTemplates] = useState(false);
  const [monochrome, setMonochrome] = useState(false);
  const [initialOpen, setInitialOpen] = useState("none");
  const [selectedMulti, setSelectedMulti] = useState([options[0]]);

  const commonFlags = {
    allowAdd: false,
    clearable: true,
    searchable: true,
    disabled,
    clearOnEscape: true,
    sizeClass: "small",
  };

  const getMenuItemTemplate = (o) => (
    <span style={{ padding: "16px" }}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <span>{o.label}</span>
      </div>
    </span>
  );

  const getSelectedItemTemplate = (o) => (
    <span
      style={{
        padding: "16px",
        display: "flex",
        alignItems: "center",
      }}
    >
      {o.label}
    </span>
  );

  const handleDropdownChangeMulti = (selectedValues) => {
    setSelectedMulti(selectedValues);
    setSelecteDeviceMeasureOption(selectedValues.map((obj) => obj.label));
  };

  return (
    <Dropdown
      placeholder="Select a Measure"
      monochrome={monochrome}
      multiselect={true}
      value={selectedMulti}
      onChange={handleDropdownChangeMulti}
      {...commonFlags}
    >
      {options.map((o, i) => (
        <DropdownOption
          key={i}
          label={o.label}
          value={o.value}
          menuItemTemplate={getMenuItemTemplate(o)}
          selectedItemTemplate={getSelectedItemTemplate(o)}
        />
      ))}
    </Dropdown>
  );
}

export default NzDropdownDeviceMeasureLegandName;
