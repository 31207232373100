import React, { useCallback, useEffect, useState } from "react";
import MicroOptMetrics from "./metrics";
import { EMCORE_DASHBOARD_BASE_API_URL } from "../../../strings";
import { fetchCharts } from "../api";

function MetricsContainer() {
    const initialMetricsData={
      totalLoadMetric:[{ metricName: "# Load Demand", metricScore:"N/A", metricStat: "" }],
      totalGreenEnergy:[{ metricName: "# Green Energy Gen.",  metricScore:"N/A", metricStat: "" }],
      totalNonGreenEnergy:[{ metricName: "# Non-Green Energy Gen.",  metricScore:"N/A", metricStat: "" }],
      totalGenerationmMetric:[{ metricName: "# Generation",  metricScore:"N/A", metricStat: "" }],
      totalEnergyCost:[{ metricName: "# Energy Cost",  metricScore:"N/A", metricStat: "" }],
      highestPeakMetric:[{ metricName: "# Highest Peak", metricScore:"N/A", metricStat: "" }],
      totalCostSavings:[{ metricName: "# Grid Intake",  metricScore:"N/A", metricStat: "" }],
      peakCosts:[{ metricName: "# Peak Costs", metricScore:"N/A", metricStat: "" }]
    };
    const [actualMetrics,setActualMetrics]=useState(initialMetricsData);
    const isFileUploaded=JSON.parse(localStorage.getItem('isFileUploaded'));
    useEffect(()=>{ },[actualMetrics])

    const fetchMetricsData = useCallback(async () => {
        try {
            const metricsApiResponse = await fetchCharts(`${EMCORE_DASHBOARD_BASE_API_URL}/output_summary.json`, {});
            console.log("metricsApiResponse",metricsApiResponse)
            const combinedMetrics = {
              totalLoadMetric:[{ metricName: "# Load Demand", metricScore: isFileUploaded? metricsApiResponse?.Total_Load.value : "N/A", metricStat: isFileUploaded? metricsApiResponse?.Total_Load.unit:"" }],

              totalGreenEnergy:[{ metricName: "# Green Energy Gen.",  metricScore: isFileUploaded? metricsApiResponse?.Total_Green_Generation.value : "N/A", metricStat: isFileUploaded? metricsApiResponse?.Total_Green_Generation.unit:"" }],

              totalNonGreenEnergy:[{ metricName: "# Non-Green Energy Gen.", metricScore: isFileUploaded? metricsApiResponse?.Total_Non_Green_Generation.value : "N/A", metricStat: isFileUploaded? metricsApiResponse?.Total_Non_Green_Generation.unit:""  }],

              totalGenerationmMetric:[{ metricName: "# Generation", metricScore: isFileUploaded ? metricsApiResponse.Total_Generation.value : "N/A",metricStat: isFileUploaded? metricsApiResponse?.Total_Generation.unit:""}],
              totalEnergyCost:[{ metricName: "# Energy Cost", metricScore: isFileUploaded ? metricsApiResponse.Total_Energy_cost.value : "N/A",metricStat: isFileUploaded? metricsApiResponse?.Total_Energy_cost.unit:""}],
              highestPeakMetric:[{ metricName: "# Highest Peak", metricScore: isFileUploaded ? metricsApiResponse?.Highest_peak.value: "N/A", metricStat: isFileUploaded? metricsApiResponse?.Highest_peak.unit:""}],
              totalCostSavings:[{ metricName: "# Grid Intake", metricScore: isFileUploaded ? metricsApiResponse.Total_Grid_Intake.value : "N/A", metricStat: isFileUploaded? metricsApiResponse?.Total_Grid_Intake.unit:""}],
              peakCosts:[{ metricName: "# Peak Costs", metricScore: isFileUploaded ? metricsApiResponse.Peak_costs.value: "N/A",  metricStat: isFileUploaded? metricsApiResponse?.Peak_costs.unit:""}],
        };
            setActualMetrics(combinedMetrics)
        } catch (error) {
            console.error('Error fetching the JSON data:', error);
        }
    }, []);

    // Fetch data on mount
    useEffect(() => {
        fetchMetricsData();
    }, [fetchMetricsData]);

  


  return (
    <div className="filter-row row" style={{ zIndex: "-1" }}>
      <div className="col-sm-6 col-md-4 col-lg-4 col-xl-2" style={{ paddingLeft: "8px", marginLeft: "24px" }}>
       <MicroOptMetrics position={3} legends={actualMetrics ? actualMetrics?.totalLoadMetric : 0} />
      </div>
      <div className="col-sm-6 col-md-4 col-lg-4 col-xl-2" style={{ paddingLeft: "8px" }}>
       <MicroOptMetrics position={3} legends={actualMetrics ? actualMetrics?.totalGreenEnergy : 0} />
      </div>
      <div className="col-sm-6 col-md-4 col-lg-4 col-xl-2" style={{ paddingLeft: "8px" }}>
       <MicroOptMetrics position={3} legends={actualMetrics ? actualMetrics?.totalNonGreenEnergy : 0} />
      </div>
      <div className="col-sm-6 col-md-4 col-lg-4 col-xl-2" style={{ paddingLeft: "8px" }}>
       <MicroOptMetrics position={3} legends={actualMetrics ? actualMetrics?.totalGenerationmMetric : 0} />
      </div>
      <div className="col-sm-6 col-md-4 col-lg-4 col-xl-2" style={{ paddingLeft: "8px" }}>
        <MicroOptMetrics position={3} legends={actualMetrics ? actualMetrics?.totalEnergyCost : 0} />
      </div>
      <div className="col-sm-6 col-md-4 col-lg-4 col-xl-2" style={{ paddingLeft: "8px" }}>
        <MicroOptMetrics position={3} legends={actualMetrics ? actualMetrics?.highestPeakMetric : 0} />
      </div>
      <div className="col-sm-6 col-md-4 col-lg-4 col-xl-2" style={{ paddingLeft: "8px" }}>
        <MicroOptMetrics position={3} legends={actualMetrics ? actualMetrics?.totalCostSavings : 0} />
      </div>
      <div className="col-sm-6 col-md-4 col-lg-4 col-xl-2" style={{ paddingLeft: "8px" }}>
        <MicroOptMetrics position={3} legends={actualMetrics ? actualMetrics?.peakCosts : 0} />
      </div>
    </div>
  );
}

export default MetricsContainer;