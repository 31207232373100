import React, { useContext } from "react";
import { Gauge } from "@abb/abb-common-ux-react";
import "./actual-power.css";
import { RootContext } from "../../../contexts/root-context";
import { useEffect } from "react";

function ActualPower() {
  const { siteKpis } = useContext(RootContext);

  const actualPower = siteKpis?.siteKPIMetrics?.actualPower?.value;
  const [value, setValue] = React.useState(actualPower || 35);
  const [range, setRange] = React.useState([0, 500]);
  const [decimals, setDecimals] = React.useState(0);
  const [warnings, setWarnings] = React.useState([125, 375]);
  const [alarms, setAlarms] = React.useState([50, 450]);
  const [limitMarkerStyle, setLimitMarkerStyle] = React.useState("none");

  // Update value state when valueForSolarTarget changes
  useEffect(() => {
    if (actualPower !== undefined) {
      setValue(actualPower);
    }
  }, [actualPower]);

  return (
    <div
      className="kpi-gauge-content"
      style={{
        width: "100%",
      }}
    >
      <Gauge
        className="nz-gauge-style"
        type="radial"
        min={range[0]}
        max={range[1]}
        minLabel={" "}
        maxLabel={" "}
        lowAlarm={alarms[0]}
        highAlarm={alarms[1]}
        lowWarn={warnings[0]}
        highWarn={warnings[1]}
        unit="kWh"
        value={value}
        limitStyle={limitMarkerStyle}
        decimals={decimals}
      />
    </div>
  );
}

export default ActualPower;
