import React, { useContext, useEffect, useRef, useState } from "react";
import * as echarts from "echarts";
import axios from "axios";
import { RootContext } from "../../../contexts/root-context";
import "./device-measures.css";
import { Link, Spinner } from "@abb/abb-common-ux-react";
import { NZ_ERROR_LIST } from "../../../strings";

const DeviceMeasures = () => {
  const {
    selectedDeviceNameOption,
    setSelectedDeviceNameOption,
    selectedDeviceMeasureOption,
    setSelecteDeviceMeasureOption,
  } = useContext(RootContext);

  useEffect(() => {
    if (selectedDeviceNameOption.length === 0) {
      setSelectedDeviceNameOption(["HVDC_2N"]);
    }
    if (selectedDeviceMeasureOption.length === 0) {
      setSelecteDeviceMeasureOption(["Frequency"]);
    }
  }, [
    selectedDeviceNameOption,
    setSelectedDeviceNameOption,
    selectedDeviceMeasureOption,
    setSelecteDeviceMeasureOption,
  ]);

  const chartRef = useRef(null);
  const [data, setData] = useState([]);
  const [isSpin, setIsSpin] = useState(true);
  const [error, setError] = useState(false);

  // Define your measureList
  const measureList = [
    { name: "f", unit: "Hz", legendName: "Frequency" },
    { name: "iL1", unit: "A", legendName: "Current Phase A" },
    { name: "iL2", unit: "A", legendName: "Current Phase B" },
    { name: "iL3", unit: "A", legendName: "Current Phase C" },
    { name: "u1", unit: "V", legendName: "Voltage phase A - neutral" },
    { name: "u2", unit: "V", legendName: "Voltage phase B - neutral" },
    { name: "u3", unit: "V", legendName: "Voltage phase C - neutral" },
    { name: "u12", unit: "V", legendName: "Voltage phase A - phase B" },
    { name: "u23", unit: "V", legendName: "Voltage phase B - phase C" },
    { name: "u31", unit: "V", legendName: "Voltage phase C - phase A" },
    { name: "p1", unit: "kW", legendName: "Active power phase A" },
    { name: "p2", unit: "kW", legendName: "Active power phase B" },
    { name: "p3", unit: "kW", legendName: "Active power phase C" },
    { name: "q1", unit: "kVAR", legendName: "Reactive power phase A" },
    { name: "q2", unit: "kVAR", legendName: "Reactive power phase B" },
    { name: "q3", unit: "kVAR", legendName: "Reactive power phase C" },
    { name: "s1", unit: "kVAR", legendName: "Apparent power phase A" },
    { name: "s2", unit: "kVAR", legendName: "Apparent power phase B" },
    { name: "s3", unit: "kVAR", legendName: "Apparent power phase C" },
    { name: "nOperations", unit: "count", legendName: "Number of operations" },
    { name: "nTrips", unit: "count", legendName: "Number of trips" },
  ];

  useEffect(() => {
    fetchData();

    const intervalId = setInterval(fetchData, 15 * 60 * 1000); // 15 min duration

    return () => clearInterval(intervalId);
  }, []);

  const fetchData = async () => {
    setIsSpin(true);
    try {
      const response = await axios.get(
        "https://kstm312sba.execute-api.us-east-1.amazonaws.com/api/measures"
      );
      setData(response.data.measures);
      setIsSpin(false);
    } catch (error) {
      setError(true);
      setIsSpin(false);
    }
  };

  // Filter data based on selected options
  const filterData = (measures, selectedDeviceNames, selectedDeviceMeasure) => {
    const refactoredMeasures = measures.map((measure) => ({
      ...measure,
      value: measure.value.map((valueObj) => ({
        ...valueObj,
        assetName: valueObj.assetName?.replace(".asset_fast", ""),
      })),
    }));

    const dayMeasures = refactoredMeasures.find(
      (measure) => measure.name === "day"
    );

    if (!dayMeasures || !dayMeasures.value || dayMeasures.value.length === 0) {
      return [];
    }

    let filteredData = dayMeasures.value.filter((valueObj) =>
      selectedDeviceNames.includes(valueObj.assetName)
    );

    if (selectedDeviceMeasure.length > 0) {
      filteredData = filteredData.filter((valueObj) => {
        const legendName = getLegendName(valueObj.measureName);
        return selectedDeviceMeasure.includes(legendName);
      });
    }

    return filteredData;
  };

  // Helper function to get legend name from measure name
  const getLegendName = (measureName) => {
    const measure = measureList.find((measure) => measure.name === measureName);
    return measure ? measure.legendName : "";
  };

  // Effect to render or update chart whenever data or selected options change
  useEffect(() => {
    if (data.length > 0 && chartRef.current) {
      const selectedDeviceNames = selectedDeviceNameOption;
      const selectedDeviceMeasure = selectedDeviceMeasureOption;
      const filteredData = filterData(
        data,
        selectedDeviceNames,
        selectedDeviceMeasure
      );

      const chart = echarts?.init(chartRef?.current);

      const uniqueAssetNames = [
        ...new Set(filteredData.map((item) => item.assetName)),
      ];
      const uniqueLegendNames = [
        ...new Set(filteredData.map((item) => getLegendName(item.measureName))),
      ];

      const series = uniqueAssetNames.flatMap((assetName, index) =>
        uniqueLegendNames.map((legendName) => {
          const seriesData = filteredData
            .filter(
              (item) =>
                item.assetName === assetName &&
                getLegendName(item.measureName) === legendName
            )
            .map((item) => ({
              name: item.datetime,
              value: item.value,
            }));

          return {
            name: `${assetName} - ${legendName} - ${
              measureList.find((measure) => measure.legendName === legendName)
                ?.unit ?? ""
            }`,
            type: "line",
            smooth: true,
            emphasis: {
              focus: "series",
            },
            data: seriesData,
            itemStyle: {
              color: getLineColor(index),
            },
          };
        })
      );

      const option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        legend: {
          data: series.map((s) => s.name),
          orient: "horizontal",
          top: "bottom",
        },
        xAxis: {
          type: "category",
          data: filteredData?.map((item) => item.datetime.substring(11, 16)),
          axisTick: {
            alignWithLabel: true,
          },
          axisLabel: {
            padding: 0,
          },
        },
        yAxis: {
          type: "value",
          axisLabel: {
            padding: 0,
            verticalAlign: "top",
            lineHeight: 10,
          },
        },
        series: series,
      };

      chart?.setOption(option);
      window.addEventListener("resize", chart.resize);

      return () => {
        window.removeEventListener("resize", chart.resize);
        chart.dispose();
      };
    }
  }, [data, selectedDeviceNameOption, selectedDeviceMeasureOption]);

  const getLineColor = (index) => {
    const colors = [
      "#3366cc", // Royal blue
      "#dc3912", // Red-orange
      "#ff9900", // Bright orange
      "#109618", // Green
      "#990099", // Violet
      "#0099c6", // Cyan
      "#dd4477", // Pink
      "#66aa00", // Lime green
      "#b82e2e", // Dark red
      "#316395", // Navy blue
      "#994499", // Purple
      "#22aa99", // Aqua
      "#aaaa11", // Yellowish green
      "#6633cc", // Deep purple
      "#e67300", // Bright orange
      "#8b0707", // Dark red
      "#651067", // Dark purple
      "#329262", // Teal
      "#5574a6", // Steel blue
    ];

    return colors[index % colors.length];
  };

  return (
    <>
      {isSpin ? (
        <Spinner
          style={{ margin: "auto" }}
          color="dark-grey"
          size="small"
        ></Spinner>
      ) : (
        <>
          {error ? (
            <span style={{ margin: "auto" }} color="dark-grey" size="small">
              {NZ_ERROR_LIST.ERROR_FETCH_DATA}
              <br />
              <Link style={{ color: "#1f1f1f" }} onClick={fetchData}>
                Retry
              </Link>
            </span>
          ) : (
            <>
              {data?.length > 0 ? (
                <div className="device-measures-container">
                  <div
                    ref={chartRef}
                    // style={{ width: "100%", height: "100%" }}
                      className="device-measures-resizable-chart"
                  />
                </div>
              ) : (
                <p className="center-text-error">{NZ_ERROR_LIST.NO_DATA}</p>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default DeviceMeasures;
