import axios from "axios";
import { EM_BASE_URL, EM_SUBSCRIPTION_KEY } from "./strings";

const api = axios.create({
  baseURL: "https://api.electrification.ability.abb/", // Your API base URL
  headers: {
    "Content-Type": "application/json",
    // 'Ocp-Apim-Subscription-Key': EM_SUBSCRIPTION_KEY,
    Connection: "keep-alive",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
    "Accept-Language": "en-US",
    // Add any common headers here
  },
});

const fetchData = async ({ method, url, headers, params }) => {
  try {
    const options = {
      method,
      url,
      headers: { ...api.defaults.headers, ...headers },
      params,
    };
    

    const response = await api.request(options);
    
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export default fetchData;
