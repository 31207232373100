import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import * as echarts from 'echarts';
import "./load-profiles.css";
import { fetchCharts } from '../../api';
import { EMCORE_DASHBOARD_BASE_API_URL } from '../../../../strings';
import { RootContext } from '../../../../contexts/root-context';
import { getEMCoreFilteredData } from '../../../../util';

const Load_Cmp_6 = (props) => {
    const chartRef = useRef(null);
    const [loadData, setLoadData] = useState([]);
    const { startTS, endTS } = useContext(RootContext);
    const [originalData,setoriginalData]=useState([]);
    //const {fetchUrl}=props;
    useEffect(()=>{
        // When filter applied
        const filteredLoads=getEMCoreFilteredData(originalData,startTS,endTS)
        const truncatedData = filteredLoads.map(item => ({
            ...item,
            timestamp:  item?.timestamp?.split(" ")[1].slice(0,5)  // Extracts the date part only
        }));
        
        setLoadData(truncatedData);
     },[startTS, endTS])
   

    // Fetch load profile data with useCallback
    const fetchLoadProfileData = useCallback(async () => {
       //first time load all data
        try {
            let loadresponse = await fetchCharts(`${EMCORE_DASHBOARD_BASE_API_URL}/load_profile_load6.json`, {});
            
            setoriginalData(loadresponse)
            const truncatedData = loadresponse.map(item => ({
                ...item,
                timestamp:  item?.timestamp?.split(" ")[1].slice(0,5) // Extracts the date part only
            }));
            setLoadData(truncatedData); // Assuming loadresponse is an array of data
        } catch (error) {
            console.error('Error fetching the JSON data:', error);
        }
    }, []);
    // const calculateMinMaxInterval = (data) => {
    //     const prices = data.map(item => (item.price / 100).toFixed(2));

    //     const min = Math.min(...prices);  // Find minimum price
    //     const max = Math.max(...prices);  // Find maximum price
    //     const interval = Math.trunc(max - min) / (prices.length - 90);  // Interval calculation


    //     return { min, max, interval };
    // };

    // const { min, max, interval } = calculateMinMaxInterval(loadData);

   
    // Fetch data on mount
    useEffect(() => {
        fetchLoadProfileData();
    }, [fetchLoadProfileData]);


    // Update chart whenever loadData changes
    useEffect(() => {
        if (!loadData.length) return; // Only initialize the chart if data is available

        const chart = echarts.init(chartRef.current);

        const option = {
            tooltip: {
                trigger: 'axis',
                formatter: (params) => {
                    return params
                        .map(item => {
                            // Choose a color based on series
                            let color = '';
                            if (item.seriesName === 'Power(kW)') {
                                color = '#e74c3c'; // Color for load
                            } 
                            return `
                                <div style="display: flex; align-items: center;">
                                   <span style="display: inline-block; width: 12px; height: 12px; background-color: ${color}; margin-right: 5px;"></span>
                                    <span>${item.seriesName}: ${parseFloat(item.data).toFixed(2)} KWh <br/></span><br/>
                                    <span>&nbsp;&nbsp;Time: ${item.name}</span>
                                </div>
                            `;
                        })
                        .join('');
                },
                position: function (point, params, dom, rect, size) {
                    const [x, y] = point; // Cursor position
                    const tooltipWidth = size.contentSize[0]; // Tooltip width
                    const viewWidth = size.viewSize[0]; // Viewport width
            
                    // Adjust position for when tooltip goes off the left or right side of the screen
                    let posX = x;
                    if (x + tooltipWidth > viewWidth) {
                        // Tooltip going off the right side, shift it to the left
                        posX = viewWidth - tooltipWidth - 10; // 10px padding from right edge
                    } 
                    if (posX < 10) {
                        // Tooltip going off the left side, shift it to the right
                        posX = 10; // 10px padding from left edge
                    }
            
                    return [posX, y];
                },
            },
            xAxis: {
                type: 'category',
                data: loadData.map(item => item.timestamp), // Assuming 'timestamp' is part of your data
                name: 'Time',
                nameLocation: 'middle',
                nameGap: 35,
                axisTick: {
                    alignWithLabel: true
                },
                axisLabel: {
                    padding: [4,-20,4,4],
                    interval: 20,
                },
            },
            yAxis: {
                type: 'value',
                name: 'Power(kW)',
                nameLocation: 'middle',
                nameRotate: 90,
                nameGap: 50, // Increase gap to ensure the y-axis name is visible
                axisLabel: {
                    padding: [10, -2, 20, 20],
                    verticalAlign: 'top',
                    lineHeight: 10,
                    // padding: [10, 20, 20, 20], // Adjust padding to give room for the y-axis labels
                },
                // interval:5000
                // min: min, // Set minimum based on the data's minimum value
                // max:max, // Set maximum based on the data's maximum value
                // interval: interval, // Set a reasonable interval for y-axis values (adjust based on the price data)
            },
            grid: {
                left: '16%', // Adjust the grid to ensure y-axis labels don't get cut off
                right: '10%',
                bottom: '23%', // Leave room for tilted x-axis labels
                top: '10%',
            },
            // legend: {
            //     data: ['Price'], // Add legend items
            //     top: '5%', // Position legend at the top
            //     icon: 'rect'
            // },
            series: [
                {
                    name: 'Power(kW)', // Name for the first legend item
                    type: 'line',
                    data: loadData.map(item => item["Power(kW)"]), // Assuming 'price' is part of your data
                    smooth: true,
                    showSymbol: false,
                    itemStyle: {
                        color: '#E12E2E' // Set line color for Active Power
                    },
                },
            ]
        };


        chart.setOption(option);

        return () => {
            chart.dispose();
        };
    }, [loadData]); // Update the chart when loadData changes

    return (
        <div className='load-profile-chart-container'>
            <div ref={chartRef} className='load-profile-chart' />
        </div>
    );
};

export default Load_Cmp_6;
