import React, { useEffect, useState, useCallback, useMemo, useContext } from "react";
import { Button, Icon } from "@abb/abb-common-ux-react";
import { RootContext } from "../../../../contexts/root-context";
import axios from "axios";
import "./network-params.css";
import { EMCORE_BASE_API_URL } from "../../../../strings";
import microGridAPICall from "../../api";

const NetworkParams = () => {
    const [loadFile, setLoadFile] = useState(null);
    const [networkFile, setNetworkFile] = useState(null);
    const [uploadStatus, setUploadStatus] = useState(''); // Track upload status
    const [lastUpdated, setLastUpdated] = useState({ network: localStorage.getItem("nw-file-last-upload") ? localStorage.getItem("nw-file-last-upload"):  null, parameters: null });
    const { setisnotify } = useContext(RootContext);

    // Handle file upload
    const uploadFiles = useCallback(async () => {
        const formData = new FormData();
        formData.append('network', networkFile);
        formData.append('parameters', loadFile);
        setisnotify("")
        try {
            const response = await microGridAPICall('post',`${EMCORE_BASE_API_URL}upload-files`, formData,
                 {
                headers: { 'Content-Type': 'multipart/form-data' },
                
            });
            setisnotify("networkfiles");
            setUploadStatus('Upload successful');
            setLastUpdated({ 
                network: new Date().toLocaleTimeString(),
                parameters: new Date().toLocaleTimeString() 
            });
        } catch (error) {
            console.error('Error uploading file:', error);
            setUploadStatus('Upload failed');
        }
        
    }, [networkFile, loadFile]);

    // Memoized file name and upload time display for network
    const networkFileDisplay = useMemo(() => (networkFile || lastUpdated.network) && (
        <>
            <b>{networkFile ? networkFile.name : ""}</b>
            <span>
                <Icon className="uploaded" name="abb/check-mark-circle-1" />
            </span>
            <span className="nw-file-upload-notification">
                Last updated {lastUpdated.network || 'N/A'}
            </span>
        </>
    ), [networkFile, lastUpdated.network]);

    // Memoized file name and upload time display for parameters
    const loadFileDisplay = useMemo(() => loadFile && (
        <>
            <b>{loadFile.name}</b>
            <span>
                <Icon className="uploaded" name="abb/check-mark-circle-1" />
            </span>
            <span className="nw-file-upload-notification">
                Last updated {lastUpdated.parameters || 'N/A'}
            </span>
        </>
    ), [loadFile, lastUpdated.parameters]);

    // Handle file input change for Network Parameters
    const handleNetworkFileChange = useCallback((event) => {
        setNetworkFile(event.target.files[0]);
    }, []);

    // Handle file input change for Load Parameters
    const handleLoadFileChange = useCallback((event) => {
        setLoadFile(event.target.files[0]);
    }, []);

    return (
        <div className="edit-user-container">
            <table id="edit-user-table">
                <tbody>
                    <tr>
                        <td className="edit-user-col">
                            <div className="nw-section">
                                <span className="inside-header">Network Parameters</span>
                                <input
                                    type="file"
                                    accept=".json"
                                    className="ip-nw-file"
                                    onChange={handleNetworkFileChange} // Track network file
                                />
                                <span style={{ display: "flex" }}>
                                    {networkFileDisplay}
                                </span>
                                
                            </div>
                            {/* <div style={{ padding: "8px" }}>
                                <Button
                                    text="Next"
                                    disabled={!networkFile} // Disable until network file is uploaded
                                    shape="pill"
                                    sizeClass="small"
                                    type="primary-black"
                                />
                                <Button
                                    text="Cancel"
                                    shape="pill"
                                    sizeClass="small"
                                />
                            </div> */}
                        </td>
                    </tr>
                    {networkFile && (
                        <tr>
                            <td className="edit-user-col">
                                {/* <div className="nw-section">
                                    <span className="inside-header">Upload Load Parameters</span>
                                    <input
                                        type="file"
                                        accept=".json"
                                        className="ip-nw-file"
                                        onChange={handleLoadFileChange} // Track load file
                                    />
                                    <span style={{ display: "flex" }}>
                                        {loadFileDisplay}
                                    </span>
                                </div> */}
                                <div style={{ padding: "8px" }}>
                                    <Button
                                        text="Upload"
                                        disabled={!networkFile } // Disable until both files are uploaded
                                        shape="pill"
                                        sizeClass="small"
                                        type="primary-black"
                                        onClick={uploadFiles} // Trigger upload
                                    />
                                    <Button
                                        text="Cancel"
                                        shape="pill"
                                        sizeClass="small"
                                    />
                                </div>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default NetworkParams;
