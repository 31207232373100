import React, { useContext } from "react";
import SldDevice from "./sld-devices";
import "./main-switchboard.css";
import sldImage from "../../../assets/net-zero-images/sld-template.png";
import { RootContext } from "../../../contexts/root-context";

const SldDiagram = () => {
    const { digitalTwinsData } = useContext(RootContext);

    console.log("setDigitalTwinsData", digitalTwinsData);

    // Function to sum power values for devices starting with 'HVAC'
    const sumPowerForHVACDevices = () => {
        let totalPower = 0;
        if (digitalTwinsData) {
            digitalTwinsData.forEach((item) => {
                if (item.device.startsWith("HVAC")) {
                    totalPower += item.power;
                }
            });
        }
        return totalPower.toFixed(2) + " KW";
    };

    // Function to find sum of power values for devices starting with 'MAIN'
    const sumPowerForMAINDevices = () => {
        let mainTotalPower = 0;
        if (digitalTwinsData) {
            digitalTwinsData.forEach((item) => {
                if (item.device.startsWith("MAIN")) {
                    mainTotalPower += item.power;
                }
            });
        }
        return parseFloat(mainTotalPower.toFixed(2)) + " KW";
    };

    let sldDevice = [
        {
            id: "MAIN_QG4.asset_fast",
            value: "10 KW",
            y: "211px",
            x: "80px",
            imgx: "166px",
            imgy: "235px",
            status: "closed",
        },
        {
            id: "SOLAR_ROOF.asset_fast",
            value: "10 KW",
            y: "211px",
            x: "240px",
            imgx: "324px",
            imgy: "235px",
            status: "closed",
        },
        {
            id: "MAIN_QG3.asset_fast",
            value: "10 KW",
            y: "211px",
            x: "405px",
            imgx: "487px",
            imgy: "235px",
            status: "closed",
        },

        {
            id: "MAIN_QG2.asset_fast",
            value: "10 KW",
            y: "211px",
            x: "559px",
            imgx: "640px",
            imgy: "235px",
            status: "closed",
        },
        {
            id: "MAIN_QG1.asset_fast",
            value: "10 KW",
            y: "211px",
            x: "712px",
            imgx: "791px",
            imgy: "235px",
            status: "closed",
        },
        //2nd 

        {
            id: "EV1.asset_fast",
            value: "0 KW",
            y: "402px",
            x: "760px",
            imgx: "800px",
            imgy: "431px",
            status: "closed",
        },
        {
            id: "HVAC_GN.asset_fast",
            value: sumPowerForHVACDevices(),
            y: "402px",
            x: "620px",
            imgx: "649px",
            imgy: "435px",
            status: "closed",
        },
        {
            id: "LOADS_NORD.asset_fast",
            value: "0 KW",
            y: "402px",
            x: "470px",
            imgx: "512px",
            imgy: "434px",
            status: "closed",
        },

        {
            id: "LOADS_SUD.asset_fast",
            value: "0 KW",
            y: "402px",
            x: "320px",
            imgx: "379px",
            imgy: "433px",
            status: "closed",
        },
        {
            id: "MAIN_GRID",
            value: sumPowerForMAINDevices(),
            y: "7px",
            x: "80px",
            imgx: "110px",//need to be fixed
            imgy: "436px",//need to be fixed
            status: "closed",
        },
        {
            id: "BUSBAR_C",
            imgx: "565px",
            imgy: "332px",
            status: "closed",
        },
        {
            id: "BUSBAR_B",
            imgx: "725px",
            imgy: "332px",
            status: "closed",
        },
        {
            id: "ITC",
            imgx: "250px",
            imgy: "435px",
            status: "closed",
        },
    ];

    // Update sldDevice with status and power from digitalTwinsData
    sldDevice.forEach((device) => {
        if (device.id === "HVAC_GN.asset_fast") {
            device.status = "closed"; // Or whatever default status you want to assign
        } else {
            const matchingDevice = digitalTwinsData?.find((item) => item.device === device.id);
            if (matchingDevice) {
                device.status = matchingDevice.status;
                device.value = matchingDevice.power + " KW";
            }
        }
    });

    return (
        <div className="parent">
            <img
                src={sldImage}
                alt="Sld image"
                height="600px"
                width="900px"
                style={{ backgroundColor: 'white' }}
            />
            {/* Render SldDevices */}
            {sldDevice.map((device) => (
                <SldDevice key={device.id} device={device} />
            ))}
        </div>
    );
};

export default SldDiagram;

