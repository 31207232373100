// AxiosService.js
import axios from 'axios';
import { useCallback } from 'react';

 const microGridAPICall = async (method, url, data = {}, headers = {}) => {
  try {
    const response = await axios({
      method,
      url,
      data,
      headers,
    });
    return response.data;
  } catch (error) {
    console.error("Axios Error:", error);
    throw error.response ? error.response.data : error.message;
  }
};

export const fetchCharts = async (url,headers = {}) => {
  try {
      const response = await fetch(url,headers);
      const jsonData = await response.json();
     return jsonData;
    
  } catch (error) {
      console.error('Error fetching the JSON data:', error);
  }
}
export const fetchInputFileData = async (url,headers = {}) => {
  try {
      const response = await fetch(url,headers);
      const jsonData = await response.json();
     return jsonData;
    
  } catch (error) {
      console.error('Error fetching the JSON data:', error);
  }
}

export default microGridAPICall;


