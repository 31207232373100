import React, { useEffect, useRef } from 'react';
import * as echarts from 'echarts';
import { Dropdown, DropdownOption } from '@abb/abb-common-ux-react';

const EnergyData = () => {
    const chartRef = useRef(null);
    const [monochrome, setMonochrome] = React.useState(false);

    useEffect(() => {
        const data = [
            { name: 'Jan', value: 100 },
            { name: 'Feb', value: 150 },
            { name: 'Mar', value: 180 },
            { name: 'Apr', value: 200 },
            { name: 'May', value: 220 },
            { name: 'Jun', value: 250 },
            { name: 'July', value: 90 },
            { name: 'Aug', value: 120 },
            { name: 'Sept', value: 180 },
            { name: 'Oct', value: 100 },
            { name: 'Nov', value: 120 },
            { name: 'Dec', value: 150 },
        ];

        const chart = echarts.init(chartRef.current);

        const option = {
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                }
            },
            xAxis: {
                type: 'category',
                data: data.map(item => item.name),
                axisTick: {
                    alignWithLabel: true
                },
                name: 'Time',
                axisLabel: {

                    padding: -2 // Add padding/margin between y-axis values and title
                },
                nameLocation: "middle",
            },
            yAxis: {
                type: 'value',
                axisLabel: {

                    padding: -15, // Add padding/margin between y-axis values and title,
                    verticalAlign: 'top', // Aligns the labels towards the top
                    lineHeight: 10, // Adjust the line height as needed

                },
                name: 'Energy [kWh]', // Title for y-axis
                nameLocation: 'center', // Set y-axis title location to start (left side)
                nameRotate: 90// Do not rotate y-axis title (name)

            },
            series: [{
                type: 'bar',
                data: data.map(item => item.value),
                itemStyle: {
                    color: '#FF4754' // Set bar color to #FF4754
                }
            }]
        };

        chart.setOption(option);

        return () => {
            chart.dispose();
        };
    }, []);

    return (

        <div className='energy-data-block'>
            <div className='indicator-container-line'>
                <Dropdown
                    label="Group"
                    placeholder="ABB SACE BUILDING - PLANT"
                    monochrome={monochrome}
                >
                    <DropdownOption key="Presenze site" label="ABB SACE BUILDING - PLANT" value="ABB SACE BUILDING - PLANT" />
                </Dropdown>
                <Dropdown

                    label="Compare Group"
                    placeholder=""
                    monochrome={monochrome}
                >
                    <DropdownOption key="Energy" label="Energy" value="Energy" />
                </Dropdown>
            </div>
            <div ref={chartRef} style={{ width: '545px', height: '280px', marginTop: "-20px" }} />
        </div>
    )
};

export default EnergyData;
