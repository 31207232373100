import React, { useState, useEffect, useMemo } from 'react';

// import { Pagination } from '../../../abb-common-ux-web/react/src/components/Pagination/Pagination';
// import { Icon } from '../../../abb-common-ux-web/react/src/components/Icon/Icon';
// import { Button } from '../../../abb-common-ux-web/react/src/components/Button/Button';
// import { Dialog } from '../../../abb-common-ux-web/react/src/components/Dialog/Dialog';
import { Pagination, Datagrid, Icon, Button, Dialog, Dropdown, DropdownOption } from "@abb/abb-common-ux-react";
import "./energy-cost-datagrid.css"
import PeriodList from '../Period-Dropdown-list/period-dropdown-list';


const EngergyCostCmp = () => {
    const [monochrome, setMonochrome] = React.useState(false);

    const lorem = useMemo(
        () =>
            'lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua ' +
            'ut enim ad minim veniam quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat duis aute irure ' +
            'dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur excepteur sint occaecat cupidatat non ' +
            'proident sunt in culpa qui officia deserunt mollit anim id est laborum',
        []
    );



    const TestComp = ({ i }) => {
        useEffect(() => {
            
            return () => {
            
            };
        }, []);

        return (
            <>
                <Icon name="abb/alarm_bell" style={{ marginRight: '16px' }} onClick={() => alert(`Clicked icon on row ${i}`)} />
                <Button sizeClass="small" text={`Button ${i}`} onClick={() => alert(`Clicked button on row ${i}`)} />
            </>
        );
    };
    const MemoizedTestComp = React.memo(TestComp);
    const groupData = [
        'POWER roof',
        'CMS700 sensors',
        'CMS700 group test',
        'solar roof',
        'ProvaGiek',
        'Solar roof less G3'
    ];

    const assetFirstCol = [
        'Roof - NORD',
        'Roof - SUD',
        'Solar - Roof',
    ];


    const assetData = lorem
        .match(/(.*?\s){2}/g)
        .map((k, i) => ({
            rowId: `row-key-${i}`,
            fields: {
                Id: `${assetFirstCol[i]}`,
                Name: k,
                Value: `${k.length * 12}`,
                Prev: `${k.length * 2}`,
                CustomContent: (
                    <div>
                        <MemoizedTestComp key={`TestComp: ${i}`} i={i} />
                        {/* Other custom content */}
                    </div>
                ),
            }
        }))
    const initialColumns = useMemo(
        () => [
            { fieldKey: 'Id', title: 'GROUP' },
            { fieldKey: 'Value', title: 'ACTIVE Energy (kWh)' },
            { fieldKey: 'Prev', title: 'Prev ACTIVE Energy (kWh)' },

        ],
        []
    );
    const initialData = useMemo(
        () =>
            lorem
                .match(/(.*?\s){2}/g)
                .map((k, i) => ({
                    rowId: `row-key-${i}`,
                    fields: {
                        Id: `${groupData[i]}`,
                        Name: k,
                        Value: `${k.length * 12}`,
                        Prev: `${k.length * 2}`,
                        CustomContent: (
                            <div>
                                <MemoizedTestComp key={`TestComp: ${i}`} i={i} />
                                {/* Other custom content */}
                            </div>
                        ),
                    },
                    expansionContent: (
                        <div
                            style={{
                                minHeight: '40px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                border: '1px dashed silver',
                                padding: '24px',
                            }}
                        >
                            <Datagrid
                                id='energy-data-asset'
                                // style={{ height: "590px" }}
                                data={assetData}
                                enableSorting={true}
                                columns={assetData.length > 0 ? initialColumns : ""}
                                rowHeight="small"
                            />

                        </div>
                    ),
                    isSelected: i % 4 === 0 ? null : false,
                    disableSelect: i % 5 === 0,
                    rowStatus: i % 7,
                    fieldStatuses: { Value: i % 7 },
                })),
        []
    );



    const [rowCount, setRowCount] = useState(initialData.length);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [data, setData] = useState(initialData);
    const [columns, setColumns] = useState(initialColumns);
    const [footerStyle, setFooterStyle] = useState('columns');
    const [columnedFooterData, setColumnedFooterData] = useState(
        initialColumns.reduce((acc, c) => {
            acc[c.fieldKey] = `Footer: ${c.title}`;
            return acc;
        }, {})
    );
    const [freeFooterData, setFreeFooterData] = useState(
        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
            <span>Some footer content here...</span>
            <span>...and more content here.</span>
        </div>
    );
    const [sortOrder, setSortOrder] = useState(undefined);
    const [columnOrder, setColumnOrder] = useState(undefined);
    const [cellStatusIndicators, setCellStatusIndicators] = useState(true);
    const [columnFilters, setColumnFilters] = useState({
        Id: { value: '', placeholder: 'ID' },
        Name: { value: '', placeholder: 'Filter name' },
        Value: { value: '', placeholder: 'Filter value' },
        Prev: { value: '', placeholder: 'Filter Prev Value' },
    });
    const [activeRow, setActiveRow] = useState(undefined);
    const [hideColumnFilters, setHideColumnFilters] = useState(false);

    const handleSelectRow = (rowIndex) => {
        let allSelected = false;
        let noneSelected = true;
        let someSelected = false;
        setData(
            data.map((d, i) => {
                const newDatum = Object.assign(d, { isSelected: rowIndex === i ? !d.isSelected : d.isSelected });
                allSelected = allSelected && d.isSelected;
                noneSelected = noneSelected && !d.isSelected;
                someSelected = someSelected || d.isSelected;
                return newDatum;
            })
        );
    };


    const handleToggleRowExpansion = (expandedRows, expandedRowIds) => {
        setData(data.map((d, i) => Object.assign(d, { isExpanded: expandedRows[i] === true })));
    };

    const handleRowActivation = (rowIndex, rowId) => {
        setData(data.map((d, i) => Object.assign(d, { isActive: d.rowId === rowId })));
        setActiveRow(data.filter((r) => r.rowId === rowId)[0]);
    };

    const deleteRow = (e, rowIndex) => {
        setData(data.filter((d, i) => i !== rowIndex).map((d, i) => Object.assign(d, { isExpanded: false })));
    };

    const handleSelectAll = (oldValue, affectedRowsBegin, affectedRowsEnd) => {
        if (oldValue === 2) {
            setData(data.map((d, i) => (d.isSelected === null || d.disableSelect ? d : i >= affectedRowsBegin && i <= affectedRowsEnd ? Object.assign(d, { isSelected: false }) : d)));
        } else {
            setData(data.map((d, i) => (d.isSelected === null || d.disableSelect ? d : i >= affectedRowsBegin && i <= affectedRowsEnd ? Object.assign(d, { isSelected: true }) : d)));
        }
    };

    const handleColumnFilterChange = (newFilters) => {
        setColumnFilters(newFilters);
        const filteredData = initialData
            .map((c) => Object.assign({}, c))
            .filter((c) => {
                return (
                    (isBlank(newFilters.Id.value) || ('' + c.fields.Id).toLowerCase().indexOf(newFilters.Id.value.toLowerCase()) > -1) &&
                    (isBlank(newFilters.Name.value) || ('' + c.fields.Name).toLowerCase().indexOf(newFilters.Name.value.toLowerCase()) > -1) &&
                    (isBlank(newFilters.Value.value) || ('' + c.fields.Value).toLowerCase().indexOf(newFilters.Value.value.toLowerCase()) > -1)
                        (isBlank(newFilters.Prev.value) || ('' + c.fields.Prev).toLowerCase().indexOf(newFilters.Prev.value.toLowerCase()) > -1)
                );
            });
        setData(filteredData);
        setRowCount(filteredData.length);
        setCurrentPage(0);
    };

    function isBlank(str) {
        return str === null || str === undefined || (typeof str === 'string' && /^\s*$/.test(str));
    }

    useEffect(() => {
        // Effect code here
        return () => {
            // Cleanup code here
        };
    }, []);

    // Handlers and other functions

    return (
        <>
            {/* Your JSX code here */}
            <div>
                <div className='indicator-container-line'>
                    <PeriodList />
                    <Dropdown
                        style={{ "visibility": "hidden" }}
                        label="Group"
                        placeholder="ABB SACE BUILDING - PLANT"
                        monochrome={monochrome}
                    >
                        <DropdownOption key="Presenze site" label="ABB SACE BUILDING - PLANT" value="ABB SACE BUILDING - PLANT" />
                    </Dropdown>
                    <Dropdown
                        style={{ "visibility": "hidden" }}
                        label="Compare Group"
                        placeholder=""
                        monochrome={monochrome}
                    >
                        <DropdownOption key="Energy" label="Energy" value="Energy" />
                    </Dropdown>

                </div>
                <Datagrid
                    id='energy-cost-compare'
                    style={{ height: '235px', width: "88%", marginTop: '5px' }}
                    monochrome={true}
                    data={data}
                    columns={columns}
                    pageSize={pageSize}
                    page={currentPage}
                    rowHeight="small"
                    zebraColoring={4}
                    enableSorting={true}
                    enableRowExpansion={true}
                    // enableRowSelection={true}
                    enableRowActivation={true}
                    enableColumnDragging={true}
                    // enableRowStatusIndicators={true}
                    // enableCellStatusIndicators={cellStatusIndicators}
                    enableColumnResize={true}
                    // footerStyle={footerStyle}
                    // footerContent={footerStyle === 'columns' ? columnedFooterData : freeFooterData}
                    onToggleRowSelect={handleSelectRow}
                    onToggleSelectAll={handleSelectAll}
                    onActivateRow={(i, rowId) => handleRowActivation(i, rowId)}
                    onToggleRowExpansion={(v, rowIds) => handleToggleRowExpansion(v, rowIds)}
                    sortOrder={sortOrder}
                    onSort={(so) => setSortOrder(so)}
                    columnOrder={columnOrder}
                    onColumnOrderChange={(order) => setColumnOrder(order)}
                    onColumnWidthsChange={(newWidths) => {
                        setColumns(
                            columns.map((c) => {
                                const obj = Object.assign(c, { width: newWidths[c.fieldKey] });
                                return obj;
                            })
                        );
                    }}
                    // columnFilters={columnFilters}
                    // hideColumnFilters={hideColumnFilters}
                    // onColumnFiltersChange={handleColumnFilterChange}
                    onClickColumnFiltersIcon={() => setHideColumnFilters(!hideColumnFilters)}
                    onClickClearColumnFilters={() => {
                        const newFilters = Object.assign({}, columnFilters);
                        Object.keys(newFilters).forEach((k) => {
                            newFilters[k].value = '';
                        });
                        handleColumnFilterChange(newFilters);
                        setHideColumnFilters(true);
                    }}
                    customMessage={data.length !== 0 ? undefined : 'Nothing in data'}
                />
                {/* <Pagination
                style={{ marginTop: '8px' }}
                // pageSizeOptions={[1, 5, 10, 50, 100]}
                // onSetPageSize={(newPageSize, newCurrentPage) => {
                //     setPageSize(newPageSize);
                //     setCurrentPage(newCurrentPage);
                // }}
                // onGotoPage={(v) => setCurrentPage(v)}
                rowCount={rowCount}
            // currentPage={currentPage}
            // currentPageSize={pageSize}
            /> */}
            </div>
        </>
    );
};

export default EngergyCostCmp;
