import React, { useEffect, useContext, useState } from "react";
import { Table, Icon, Spinner } from "@abb/abb-common-ux-react";
import "./operation-status.css";
import axios from "axios";
import { RootContext } from "../../contexts/root-context";
import { BASE_URL } from "../../strings";
import { getOpsStatusIcon } from "../../util";

function OperationStatus() {
  const { setSpinner, startTS, endTS } = useContext(RootContext);
  const [operationStatusResponse, setoperationStatusResponse] = useState([]);
  const [isSpin, setispin] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          BASE_URL +
            `SMDemo-OperationStatusServiceLambda?startDateTime=${startTS}&endDateTime=${endTS}`
        );
        setoperationStatusResponse(response.data.equipmentStatusList);
      } catch (error) {
        console.log(error);
        setSpinner(true);
      } finally {
        setispin(false);
      }
    };

    fetchData();
  }, [startTS, setSpinner]);

  return (
    <>
      {isSpin ? (
        <Spinner style={{ margin: "auto" }} color="dark-grey" size="small" />
      ) : (
        <Table id="ops-status" borderType="none">
          <tbody>
            {operationStatusResponse?.map((operationResponseItem, index) => (
              <tr key={index}>
                <td className="equipmentName">
                  {operationResponseItem.equipName}
                </td>
                <td className="equipmentStatus">
                  <span>
                    <Icon
                      name={getOpsStatusIcon(operationResponseItem.status).icon}
                      className={
                        getOpsStatusIcon(operationResponseItem.status).class
                      }
                    />
                    {operationResponseItem.status}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </>
  );
}

export default OperationStatus;
