import React, { Component } from "react";
import { Layer } from "recharts";

export default class DemoSankeyLink extends Component {
  state = {
    isHovered: false,
  };

  handleMouseEnter = () => {
    this.setState({ isHovered: true });
  };

  handleMouseLeave = () => {
    this.setState({ isHovered: false });
  };

  render() {
    const {
      sourceX,
      targetX,
      sourceY,
      targetY,
      sourceControlX,
      targetControlX,
      linkWidth,
      index,
      data, // Receive data prop directly
    } = this.props;
    const { isHovered } = this.state;

    // Define gradient ID to be used for fill
    const gradientId = `linkGradient-${index}`; // Unique ID per link

    // Determine stopOpacity based on hover state
    const stopOpacity = isHovered ? 0.8 : 0.6;
    // || index === 2 for Ev chargers
    let fillColor = index === 1 ? "#008000" : "#ff9e2f";
    return (
      <Layer key={`CustomLink${index}`}>
        <defs>
          <linearGradient id={gradientId} x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" style={{ stopColor: fillColor, stopOpacity }} />
            <stop offset="100%" style={{ stopColor: fillColor, stopOpacity }} />
          </linearGradient>
        </defs>
        <path
          d={`
          M${sourceX},${sourceY + linkWidth / 2}
          C${sourceControlX},${sourceY + linkWidth / 2}
            ${targetControlX},${targetY + linkWidth / 2}
            ${targetX},${targetY + linkWidth / 2}
          L${targetX},${targetY - linkWidth / 2}
          C${targetControlX},${targetY - linkWidth / 2}
            ${sourceControlX},${sourceY - linkWidth / 2}
            ${sourceX},${sourceY - linkWidth / 2}
          Z
        `}
          fill={`url(#${gradientId})`}
          strokeWidth="0"
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
        />
      </Layer>
    );
  }
}
